import React from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {convertToInternationalCurrencySystem} from '../../../common/utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";




const StatisticsCard = ({dashboardDetails = {}, vaultDetails={}}) =>{
    return (
      <Col md={6}>
        <div className="dashboard-card">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="title-white">Statistics</h4>
            {/* <Link to="/vault-history">
              <button className="btn card-btn">Vault History</button>
            </Link> */}
          </div>

          <div className="d-flex align-items-center justify-content-between card-body-row">
            <p className="card-body-name">VAULTS</p>
            <p className="card-body-value">{vaultDetails.vaultSize ? vaultDetails.vaultSize : '0.00'}</p>
          </div>

          {/* <div className="d-flex align-items-center justify-content-between card-body-row">
                <p className="card-body-name">ETH Supply</p>
                <p className="card-body-value">459k</p>
              </div> */}

          <div className="d-flex align-items-center justify-content-between card-body-row">
            <p className="card-body-name">
              Total Collateral &nbsp;
              {["top"].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>
                      Total ether in the pool in $
                    </Tooltip>
                  }
                >
                  <button className="btn p-0">
                    <FontAwesomeIcon
                      className="text-primary"
                      icon={faCircleInfo}
                    />
                  </button>
                </OverlayTrigger>
              ))}
            </p>

            <p className="card-body-value">
              ${" "}
              {dashboardDetails.tvlVal
                ? convertToInternationalCurrencySystem(dashboardDetails.tvlVal)
                : "0.00"}
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-between card-body-row">
            <p className="card-body-name">Ether Price</p>
            <p className="card-body-value">
              $ {dashboardDetails.marketPrice ? dashboardDetails.marketPrice : "0.00"}
            </p>
          </div>
        </div>
      </Col>
    );
}

export default StatisticsCard