import React from "react";
import { Col } from "react-bootstrap";
import {truncateToDecimals} from '../../../common/utils'
import add from '../../../../assets/svg/metamask.svg'
import stakeVault from "../../../../assets/svg/stack-vault.svg";
import { useNavigate  } from "react-router-dom";
import {addCustomTokenMetamask} from '../helper'
import {convertToInternationalCurrencySystem} from '../../../common/utils'
import usdao from "../../../../assets/svg/usdao-icon.svg";
import { Link } from "react-router-dom";


const UsdaoCard = (props) =>{
  let navigate = useNavigate();
  let {stakeDetails = {}, dashboardDetails = {}} = props;

    return(
        <Col md={6}>
            <div className="dashboard-card">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="title-primary">USDAO</h4>
                <div className="price-wrap">
                  <p>
                    Price <span>$ 1.00</span>
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between card-body-row">
                <p className="card-body-name">Total supply</p>
                <p className="card-body-value">{dashboardDetails.totalSupplyUsm ? convertToInternationalCurrencySystem(dashboardDetails.totalSupplyUsm) : '0.00'}</p>
              </div>

              <div className="d-flex align-items-center justify-content-between card-body-row">
                <p className="card-body-name">Total Staked USDAO</p>
                <p className="card-body-value">{stakeDetails.usdaoStakedVal ? truncateToDecimals(stakeDetails.usdaoStakedVal) : 0.00 }</p>
              </div>

              <div className="card-footer-info">
                <button className=" btn card-btn" onClick={()=>addCustomTokenMetamask('usm','USDAO')}>
                  <img src={add} alt="" />
                  <p>Add</p>
                </button>

                <Link className="btn card-btn "  to="/vault">
                  <img src={usdao} alt="" />
                  <p>Buy</p>
                </Link>

                <button className="btn card-btn" onClick={() => navigate("/stake")}>
                  <img src={stakeVault} alt="" />
                  <p>Stake</p>
                </button>
              </div>
            </div>
        </Col>
    )
}

export default UsdaoCard