import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux'
import { Col, Row, OverlayTrigger, Tooltip, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbarWithChildren, buildStyles  } from "react-circular-progressbar";
import {Link} from 'react-router-dom'
import swal from "sweetalert";

import TotalSupply from '../../common/TotalSupply/totalSupply';
import Deposite from "./TabContent/Deposite";
import { loadAllData } from "../../common/Layout/layoutWrapComp";
import {setDashboardDetails,setVaultDetails, setStakeDetails } from "../../redux/action";
import {fetchAllInitialValues, getTCR, fetchInitialAccVal, getAccountDetails, sortedVaultSize } from '../../blockchain/commonFunction';
import {truncateToDecimals, convertToInternationalCurrencySystem, alertRecovery} from '../../common/utils'
import {getVaultStatusFn, getVaultDebtColl, collateralFn, closeVaultFn, checkRecovery, sendColltoVaultFn} from './helper'
import {getStakedRewardVal} from '../Stake/helper'
import "react-circular-progressbar/dist/styles.css";
import './vault.scss';
import './tabcontent.scss';
import AddEth from "./TabContent/AddEth";
import WithdrawEth from "./TabContent/WithdrawEth";
import RepayUsdao from "./TabContent/RepayUsdao";
import BorrowUsdao from "./TabContent/BorrowUsdao";
import {setTransactionLoader} from './../../redux/action';


const Vault = ({setAlertType}) => {

    const dispatch = useDispatch()
    const selector = useSelector((state =>state))

    let {dashboardDetails = {}, accountDetails = {}, vaultDetails = {}, stakeDetails = {}} = selector;

    const [ratio, setRatio] = useState();
    const [closeButtonStatus,  setCloseButtonStatus] = useState(false);

    const refreshAllData= () =>{
      dispatch(setTransactionLoader(true))
      loadAllData(setAlertType, vaultDetails, dashboardDetails, stakeDetails, accountDetails, dispatch)
    }
    
    useEffect(()=>{
      (async function(){
        await alertRecovery(vaultDetails)
      })();
    },[])

    useEffect(()=>{

      (async function(){
        setRatio(vaultDetails.ratio)
      })();
    },[vaultDetails])

    const onClose = async() =>{
      setCloseButtonStatus(true);
      let {debt} = vaultDetails
          const vaultSize = await sortedVaultSize()
          
          if(vaultDetails.recoveryModeCheck){
            setCloseButtonStatus(false);
    
            return swal('Cannot close trove when the system is in recovery mode')
          }
          if(parseFloat(accountDetails.usmBalance) < (parseFloat(debt)-200)){
            setCloseButtonStatus(false);
    
            return swal(`You’ll need ${debt-200} USDAO to close the vault`)
          }
          if(parseFloat(vaultSize) === 1){
            setCloseButtonStatus(false);
    
            return swal('Cannot close the last vault in the system')
          }
          await closeVaultFn(refreshAllData, setCloseButtonStatus)
     
    }


    const maxProgressCircleVal = (val) =>{
      if(val > 100 && val <= 500){
        return 500
      }else if(val > 500 && val <= 1000){
        return 1000
      }else if(val > 1000 && val <= 10000){
        return 10000
      }else if(val > 10000 && val <= 100000){
        return 100000
      }
      return 100
    }

  const sendColltoVault = async() =>{
    await sendColltoVaultFn()
  }

  return (
    <div className="d-flex align-items-center flex-column">
      <div className="vault-card">
        <div className="dashboard-card">
          {!!vaultDetails.vaultStatus && !!parseFloat(stakeDetails.ethGainVal) && <h7 className="text-primary mb-3">
            You have unclaimed ETH Gain <b>({stakeDetails ? stakeDetails.ethGainVal : '0.00'})</b>. Send it to Vault?
          </h7>}
          <div className="d-flex justify-content-between align-items-center">
            { !!vaultDetails.vaultStatus && !!parseFloat(stakeDetails.ethGainVal) && <button className="btn btn-gradient mb-0" onClick={sendColltoVault}>Confirm</button>}
            <div className="filler-div"></div>
            <Link to="/vault-history">
              <button className="btn btn-gradient mb-0">View Vault History</button>
            </Link>
          </div>
        </div>
      </div>

      <TotalSupply
        vaultDetails={vaultDetails}
        dashboardDetails={dashboardDetails}
        onClose={onClose}
        closeButtonStatus={closeButtonStatus}
        showClose = {true}
      />

      <div className="vault-card">
        <div className="dashboard-card">
          <Row className="gy-4">
            <Col md={4}>
              <h4 className="title-primary">
                Deposit(ETH){" "}
                {["top"].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>
                        Deposited collateral.
                      </Tooltip>
                    }
                  >
                    <button className="btn pt-0 pb-0 ps-2 pe-2">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </button>
                  </OverlayTrigger>
                ))}
              </h4>

              <p className="card-body-value text-start mb-2">
                {!!vaultDetails.vaultStatus ? truncateToDecimals(vaultDetails.coll) : "0.00"}{" "}
                ETH
              </p>
            </Col>

            <Col md={4}>
              <div className="ration-progress">
                <CircularProgressbarWithChildren
                  value={ratio}
                  maxValue={maxProgressCircleVal(Number(ratio))}
                  styles={buildStyles({
                    pathColor: `${
                      ratio < 110
                        ? "#FE4242"
                        : ratio < 125
                        ? " #FF7A00"
                        : ratio > 125
                        ? "#51FE42"
                        : "#d6d6d6"
                    }`,
                  })}
                >
                  <p className="card-body-name mb-0">
                    {ratio ? `${truncateToDecimals(ratio)}%` : "--"}
                  </p>
                  <p className="card-body-name mb-0">
                    Ratio
                    {["top"].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Vault's ICR(Internal Collateral ratio) is the ratio
                            of the dollar value of its entire ETH collateral at
                            the current ETH:USD price, to its entire USDAO debt.
                          </Tooltip>
                        }
                      >
                        <button className="btn p-0">
                          <FontAwesomeIcon icon={faCircleInfo} />
                        </button>
                      </OverlayTrigger>
                    ))}
                  </p>
                </CircularProgressbarWithChildren>
              </div>
            </Col>

            <Col md={4}>
              <h4 className="title-primary">
                Debt(USDAO){" "}
                {["top"].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>
                        Amount of USDAO Borrowed against the collateral
                        provided.
                      </Tooltip>
                    }
                  >
                    <button className="btn pt-0 pb-0 ps-2 pe-2">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </button>
                  </OverlayTrigger>
                ))}
              </h4>

              <p className="card-body-value text-start mb-2">
                {!!vaultDetails.vaultStatus ? truncateToDecimals(vaultDetails.debt) : "0.00"}{" "}
                USDAO
              </p>
            </Col>

            <Col md={12}>
              {!vaultDetails.vaultStatus && (
                <Deposite
                  accountDetails={accountDetails}
                  setRatio={setRatio}
                  vaultDetails={vaultDetails}
                  refreshAllData={refreshAllData}
                  ratio={ratio}
                />
              )}

              {!!vaultDetails.vaultStatus && (
                <Tabs
                  defaultActiveKey={"add-eth"}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  unmountOnExit={true}
                >
                  <Tab eventKey="add-eth" title="Add ETH">
                    <AddEth
                      refreshAllData={refreshAllData}
                      accountDetails={accountDetails}
                      vaultDetails={vaultDetails}
                      setRatio={setRatio}
                    />
                  </Tab>
                  <Tab eventKey="withdraw-eth" title="Withdraw ETH">
                    <WithdrawEth
                      refreshAllData={refreshAllData}
                      accountDetails={accountDetails}
                      vaultDetails={vaultDetails}
                      setRatio={setRatio}
                      ratio={ratio}
                    />
                  </Tab>
                  <Tab eventKey="borrow" title="Borrow USDAO">
                    <BorrowUsdao
                      refreshAllData={refreshAllData}
                      accountDetails={accountDetails}
                      vaultDetails={vaultDetails}
                      setRatio={setRatio}
                      ratio={ratio}
                    />
                  </Tab>
                  <Tab eventKey="repay" title="Repay USDAO">
                    <RepayUsdao
                      refreshAllData={refreshAllData}
                      accountDetails={accountDetails}
                      vaultDetails={vaultDetails}
                      setRatio={setRatio}
                    />
                  </Tab>
                </Tabs>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Vault
