import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import UsdaoCard from "./Card/UsdaoCard";
import AssetCard from "./Card/AssetCard";
import StatisticsCard from "./Card/StatisticsCard";
import TCRCard from "./Card/TCRCard";
import WalletCard from "./Card/WalletCard";
import ClaimCard from "./Card/ClaimCard";
import { alertRecovery } from "../../common/utils";

import {
  setDashboardDetails,
  setVaultDetails,
  setStakeDetails,
} from "../../redux/action";
import {
  sortedVaultSize,
  fetchAllInitialValues,
  getTCR,
} from "../../blockchain/commonFunction";
import { getAssetReward, getStakedRewardVal } from "../Stake/helper";
import { showClaimFn, assetCirculationFn } from "./helper";
import "./dashboard.scss";
import {
  checkRecovery,
  getVaultDebtColl,
  getVaultStatusFn,
} from "../Vault/helper";

const Dashboard = ({ setAlertType }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  let {
    dashboardDetails = {},
    accountDetails = {},
    vaultDetails = {},
    stakeDetails = {},
  } = selector;

  useEffect(() => {
    (async function () {
      await alertRecovery(vaultDetails);
    })();
  }, []);

  return (
    <>
      <Row className="gy-3">
        <UsdaoCard
          stakeDetails={stakeDetails}
          dashboardDetails={dashboardDetails}
        />
        <StatisticsCard
          dashboardDetails={dashboardDetails}
          vaultDetails={vaultDetails}
        />
        {/* <AssetCard dashboardDetails={dashboardDetails}/> */}
        <TCRCard dashboardDetails={dashboardDetails} />
        {!!dashboardDetails.showClaimBtn && (
          <ClaimCard dashboardDetails={dashboardDetails} />
        )}
        <WalletCard
          dashboardDetails={dashboardDetails}
          accountDetails={accountDetails}
        />
      </Row>
    </>
  );
};

export default Dashboard;
