import React from "react";
import { Col } from "react-bootstrap";
import {sendColltoVaultFn, claimFn} from '../helper'

const ClaimCard = ({dashboardDetails={}}) =>{
    
      const onClaim = async() =>{
        await claimFn()
      }

    return (
      <Col md={6}>
        <div className="dashboard-card">
          

          <div className="align-items-center justify-content-between card-body-row">
            <p className="card-body-name cliam-card-head">You have unclaimed ETH.</p>
          </div>
          <div className="card-footer-info claim-btn">
          { !!dashboardDetails.showClaimBtn &&  <button className=" btn card-btn me-3"  onClick={onClaim} >Claim</button>}
          </div>
        </div>
      </Col>
    );
}

export default ClaimCard