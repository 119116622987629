import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import eth from "../../../../assets/img/eth.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faUniversalAccess } from '@fortawesome/free-solid-svg-icons';
import {addEth, collateralFn, liquidationReserve, liquidationPriceFn, totalDeptFn} from '../helper';
import {truncateToDecimals} from '../../../common/utils';


var cn = require('classnames');

const AddEthCal = ({addEthVal, calcualtion, vaultDetails}) =>{

  return(
    <div className="deposite-info">

        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>Deposit</p>

          <p>{truncateToDecimals(addEthVal, 6)}  ETH</p>
        </div>

        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>
            Liquidation reserve
            {["top"].map((placement) => (
              <OverlayTrigger
              key={placement}
              placement={placement}
              overlay={
                  <Tooltip id={`tooltip-${placement}`}>
                  200 USDAO reserve will be refunded/burnt once vault is closed the owner
                  </Tooltip>
              }
              >
              <button className="btn pt-0 pb-0 ps-2 pe-2">
                  <FontAwesomeIcon icon={faCircleInfo} />
              </button>
              </OverlayTrigger>
          ))}
          </p>

          <p>{liquidationReserve} USDAO</p>
        </div>

        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>
            Total debt
          </p>

          <p>{truncateToDecimals(vaultDetails.debt)} USDAO</p>
        </div>
    </div>
  )
}

function AddEth({refreshAllData, accountDetails, vaultDetails, setRatio}) {  

  const [addEthVal, setAddEthVal] = useState()
  const [calcualtion,  setCalculation] = useState({})
  const [disable, setDisable] = useState(false)

  const calculateRatio = async(addEthVal) =>{
    let {coll, debt} = vaultDetails;
    addEthVal = addEthVal || '0'
    coll = coll || '0'
    debt = debt || '0'
    coll = parseFloat(coll) + parseFloat(addEthVal)
    let dynamicRatio = await collateralFn(coll.toString(), debt)
    setRatio(dynamicRatio)
  }


  useEffect(()=>{
    (async function(){
    var number = document.getElementById('number');

    if(number){
      document.onkeydown = function(e) {
        if (e.keyCode === 109 || e.keyCode === 189) {
          return false
        }
      }
    }
    setRatio(vaultDetails.ratio)
    return () => {
      setAddEthVal()
    };
  })();
  },[])

  const addEthChangeHandler = async(e) =>{
    let {value} = e.target
    setAddEthVal(value)

    await calculateRatio(value, vaultDetails)
    let liquidationPrice =  await liquidationPriceFn  (value);
    let totalDept = await totalDeptFn(value)
    setCalculation({
      liquidationPrice : liquidationPrice,
      totalDept : totalDept
    })
  }

  const addEthOnSubmit = async() =>{
    setDisable(true)
    if(addEthVal  > parseFloat(accountDetails.etherBalance)){
      setDisable(false)
      swal('Insufficient funds')
    }else{
      await addEth(addEthVal, refreshAllData, setDisable)
    }
     
  }

  const maxHandler = async() =>{
      const maxEther = accountDetails && accountDetails.etherBalance ? accountDetails.etherBalance : '0.00';
      calculateRatio(maxEther, vaultDetails)
      setAddEthVal(maxEther)
  }

  return (
    <>
      <div className="tab-box-wrap">
        <div className="tab-box-info d-flex justify-content-between align-items-center">
          <p>Deposit</p>
          <p>Wallet: {accountDetails && accountDetails.etherBalance ? truncateToDecimals(accountDetails.etherBalance, 6) : '0.00'}</p>
        </div>

        <div className="tab-box-values d-flex justify-content-between align-items-center">
          <input type="number" placeholder='Enter amount' id="number" onChange={addEthChangeHandler} value={addEthVal} min="0.01" step="0.01"/>
          <div className="d-flex">
            <div className="value-status"  onClick={maxHandler}>
              <p>Max</p>
            </div>
            <img src={eth} alt="" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mb-5">
        <a
          onClick={addEthOnSubmit}
          rel="noreferrer"
          className={cn("menu-link btn btn-gradient", (!parseFloat(addEthVal) || disable) && 'disabled')}
        >
          Confirm
        </a>
      </div>
      {addEthVal && <AddEthCal addEthVal={addEthVal} calcualtion={calcualtion} vaultDetails={vaultDetails}/>}
    </>
  );
}

export default AddEth
