import React from "react";
import { Col, Row } from "react-bootstrap";
import "./footer.scss";
import logo from '../../../assets/svg/logo.svg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
  return (
    <div className="footer padding-x">
      <Row className="gx-5">
        <Col md={4} lg={3}>
          <Link to="/" className="footer-logo d-flex justify-content-center">
            <img src={logo} alt="footer logo" />
          </Link>

          <p className="footer-desc text-center text-white">
            USDAO is a decentralized, community-based, multi collateral-backed
            cryptocurrency soft-pegged to the US dollar. It is designed to be
            resilient during market crash and hyperin flation which is ensured
            by ASSET token which is a USDAO pool stabilizer, USDAO offers owning
            US dollar without having a bank account and provide economic freedom
            and opportunity globally to anyone.
          </p>

          <div className="footer-social">
            <a
              href="https://twitter.com/usdao_io"
              target="_blank" rel="noreferrer"
              className="footer-social-link"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>

            <a
              href="https://discord.gg/JHbXb4kQwG"
              target="_blank" rel="noreferrer"
              className="footer-social-link"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </a>

            <a
              href="https://github.com/USDAO-Protocol"
              target="_blank" rel="noreferrer"
              className="footer-social-link"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </Col>

        <Col md={8} lg={9}>
          <Row className="gy-5">
            <Col md={4} lg={3}>
              <div className="footer-link-wrap">
                <p className="footer-links-title">About</p>
                <ul>
                  <li>
                    <a
                      href="https://usdao.io/#/stablecoin"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      USDAO Stablecoin
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://usdao.io/#/daogov"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      DAOGOV
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://usdao.io/#/asset"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      ASSET Token
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.usdao.io/docs/for-businesses"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      For Businesses
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.usdao.io/docs/for-developers"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      For Developer
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={4} lg={3}>
              <div className="footer-link-wrap">
                <p className="footer-links-title">Explore</p>
                <ul>
                  <li>
                    <a
                      href="https://usdao.io/#/staking"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Staking
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://usdao.io/#/daogov"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Governance
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={4} lg={3}>
              <div className="footer-link-wrap">
                <p className="footer-links-title">Library</p>
                <ul>
                  <li>
                    <a
                      href="https://gateway.pinata.cloud/ipfs/QmTEbdJfRyUhnobYBvDsJAcnKT1sSiyQoKJE3sSXPY6h3K"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Whitepaper
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://gateway.pinata.cloud/ipfs/QmdswTUmecLp3rRn2gHiw2eZhBr3a15Pofu947RTXKBND5"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Portfolio
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={4} lg={3}>
              <div className="footer-link-wrap">
                <p className="footer-links-title">Developers</p>
                <ul>
                  <li>
                    <a
                      href="https://docs.usdao.io/docs/"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Docs
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.usdao.io/docs/getting-started/for-developers"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Developer Resources
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.usdao.io/docs/usdao-stack/smart-contract-modules/oracle-module"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Price Feeds
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={4} lg={3}>
              <div className="footer-link-wrap">
                <p className="footer-links-title">Contact</p>
                <ul>
                  <li>
                    <a
                      href="https://app.usdao.io/contactus"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://usdao.medium.com/"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://app.usdao.io/contactus"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Support
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://app.usdao.io/contactus"
                      target="_blank" rel="noreferrer"
                      className="footer-link"
                    >
                      Press inquiries
                    </a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col md={4} lg={3}>
              <div className="footer-link-wrap">
                <p className="footer-links-title">Partners</p>
                <ul>
                  <li>
                    <a href="https://usdao.io/#/" target="_blank" rel="noreferrer" className="footer-link">
                      USDAO Foundation
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
