import React, {useState, useEffect} from 'react'
import swal from 'sweetalert';
import {onsubmitValidation, redeem, stake, sendColltoVaultFn} from './helper'
import {getVaultStatusFn} from '../Vault/helper'
import { Link } from 'react-router-dom';

var cn = require('classnames');

function StakeBox({stakeDetails, refreshAllData, accountDetails}) {

  let {assetRewardVal = '', totalStakeVal = '', ethGainVal=''} = stakeDetails;

  let [data, setData] = useState({})
  let [error, setError] = useState({})
  let [disable, setDisable] = useState({})
 


  const validateFields = {
    inputVal : {
        required : true,
        errorMessage : 'Please enter the Amount.'
    }
  }



  const handleSubmit = async(type) =>{
    const errorObject = await onsubmitValidation(
      data,
      validateFields,
      setError
    );
    if (!Object.keys(errorObject).length){
      if(type === 'stake'){
        if (parseFloat(data.inputVal) > parseFloat(accountDetails.usmBalance)) {
          setDisable({})
          return swal(`User has insufficient balance.`)
        }
        setDisable({'disableRedeem' : true, 'disableStake' : true})
        await stake(data.inputVal, setData, setDisable,  refreshAllData)
      }else{
        setDisable({'disableRedeem' : true, 'disableStake' : true})
        if (parseFloat(stakeDetails.totalStakeVal) === parseFloat(0)) {
          setDisable({})
          return swal(`User has insufficient balance.`)
        }
        if (parseFloat(data.inputVal) > parseFloat(stakeDetails.usdaoStakedVal)) {
          setDisable({})
          return swal(
            `Entered Amount is more than the usdao staked balance! Please Re-enter the amount and try again`
          )
        }
        await redeem(data.inputVal, setData, setDisable, refreshAllData)
      }
        
    }
  }

  const changeHandler = (e) =>{
    let {value} = e.target;
      setData({inputVal : value})
      setError({})
  }

  const maxHandler = ()=>{
    setData({inputVal:String(accountDetails.usmBalance)})
  }



  return (
    <div className="dashboard-card">
      {/* <p className="card-body-value text-start mb-2">Stake USDAO</p> */}
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="title-white">Stake USDAO</h4>
        <Link to="/reward-history">
          <button className="btn btn-gradient"> View Reward History</button>
        </Link>
      </div>
      <div className="stack-amount-wrap">
        <div className="label">
          Enter Amount <span>*</span>
        </div>

        <div className="input-wrap d-flex flex-column justify-content-between">
          <div className="input-group-wrap d-flex align-items-center justify-content-between">
            <input
              type="tel"
              placeholder="Enter Amount"
              onChange={changeHandler}
              value={data.inputVal ? data.inputVal : ""}
            />
            <div className="value-status" onClick={maxHandler}>
              <p>Max</p>
            </div>
          </div>
          {error.inputVal && <div className="error">{error.inputVal}</div>}

          <div className="d-flex justify-content-start stack-btn-wrap">
            <button
              className={cn(
                "btn btn-outline w-fit me-3",
                disable.disableStake && "disabled"
              )}
              onClick={() => handleSubmit("stake")}
            >
              Stake
            </button>
            <button
              className={cn(
                "btn btn-outline w-fit me-3",
                !parseFloat(totalStakeVal) && "disabled",
                disable.disableRedeem && "disabled"
              )}
              onClick={() => handleSubmit("redeem")}
            >
              Redeem
            </button>

            {/* {!!parseFloat(ethGainVal) && vaultStatus && <button className= {cn('btn btn-outline w-fit me-3', disable.disableSendColl && 'disabled' )} onClick={sendColltoVault}>Send Coll to Vault</button>} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StakeBox