import { ethers } from 'ethers';
import { contractDetails } from './contractDetails';

// const provider = new ethers.providers.JsonRpcProvider(
// 	'https://rinkeby.infura.io/v3/b001cbdee80c4e52806e2e072e601ce4'
// )
const bigNumber = 1.1579208923731621e+61;


const getMetaMask = () => {
	const providerMetaMask = new ethers.providers.Web3Provider(window.ethereum)
	return providerMetaMask
}

const getAccountDetails = async(setAlertMetaMaskLogOut) =>{
    try{
        const details = {};
        const accounts =  await window.ethereum.request({ method: 'eth_requestAccounts' })
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    
        const networkName = await provider.getNetwork();
        const chainId = await window.ethereum.request({ method: 'net_version' });
        const balance = await provider.getBalance(accounts[0]);
    
        details.account = accounts[0];
        details.networkName = networkName.name;
        details.chainId = chainId;
        details.balance = ethers.utils.formatEther(balance, 18)
        if(accounts.length){
            return details
        }else{
            console.log("ERROR IN  METAMASK CONNECTION")
        }
    }catch(error){ 
        setAlertMetaMaskLogOut && setAlertMetaMaskLogOut(true)
		return false
	}
    
}


const getChainId = async () => {
    try{
        const network = await getAccountDetails()
        return network.chainId
    }catch(error){
        console.log(error)
    }
	
}

const getNetworkName = async () => {
    try{
        const accountDetails = await getAccountDetails();
        return accountDetails.networkName
    }catch(error){
        console.log(error)
    }
	
}


const readContractFunction = async (token) => {
	const chainId = await getChainId()
    const provider = await getMetaMask()
	return new ethers.Contract(
		contractDetails[token].address[chainId],
		contractDetails[token].abi,
		provider
	)
}

const writeContractFunction = async (token) => {
	const mProviderInner = getMetaMask()
	const signer = await mProviderInner.getSigner()
	const chainId = await getChainId()
	return new ethers.Contract(
		contractDetails[token].address[chainId],
		contractDetails[token].abi,
		signer
	)
}

const getContractAddress = async (token) => {
    try{
        const chainId = await getChainId()
        return contractDetails[token].address[chainId]
    }catch(error){
        console.log(error)
    }
}

const getAddress = async () => {
    try{
        const accountDetail = await getAccountDetails()
        return accountDetail.account
    }catch(error){
        console.log(error)
    }
}

const getEtherBalance = async() => {
    try{
        const accountDetail = await getAccountDetails()
        return accountDetail.balance
    }catch(error){
        console.log(error)
    }
    
}

const balanceOf = async (token) => {
    try{
        const readFunction = await readContractFunction(token)
        const balance = await readFunction.balanceOf(await getAddress())
        const decimal = await readFunction.decimals()
        const formatedBalance = ethers.utils.formatUnits(balance, decimal)
        return parseFloat(formatedBalance)
    }catch(error){
        console.log(error)
    }
    
}


const marketPriceFn = async(usmContract) => {
    try{
        const marketPrice = await usmContract.latestPrice()
        return Number(marketPrice) / 10e17
    }catch(error){
        console.log(error)
    }
    
}

const marketPriceWei = async() => {
    try{
        const usmContract = await readContractFunction('usm')
        const marketPrice = await usmContract.latestPrice()
        return marketPrice
    }catch(error){
        console.log(error)
    }
    
}

const totalSupply = async(contract) => {
    try{
        const totalSupply = await contract.totalSupply()
        return totalSupply
    }catch(error){
        console.log(error)
    }
    
}


const ethPoolFn = async(contract) =>{
    try{
        const ethPool = await contract.ethPool()
        return ethPool
    }catch(error){
        console.log(error)
    }
    
}

const fetchAllInitialValues = async() =>{
    try{
        const usmContract = await readContractFunction('usm')
        const fumContract = await readContractFunction('fum')
        const marketPrice = await marketPriceFn(usmContract);
        let  ethPool = await ethPoolFn(usmContract)
        let totalSupplyUsm = await totalSupply(usmContract)
        let totalSupplyFum = await totalSupply(fumContract)
        const decimal = await usmContract.decimals()
        totalSupplyUsm = ethers.utils.formatUnits(totalSupplyUsm, decimal)
        totalSupplyFum = ethers.utils.formatUnits(totalSupplyFum, decimal)
        ethPool = ethers.utils.formatUnits(ethPool)
        const tvlVal = ethPool * marketPrice
        return {marketPrice, totalSupplyUsm, totalSupplyFum, ethPool, tvlVal}
    }catch(error){
        console.log(error)
    }
    
}


const fetchInitialAccVal = async() =>{
    try{
        const usmBalance = await balanceOf('usm')
        const assetBalance = await balanceOf('fum')
        const etherBalance = await getEtherBalance()
        return {usmBalance, assetBalance, etherBalance}
    }catch(error){
        console.log("error in fetch")
    }
    
}

const getTCR = async() =>{
    try{
        const contract = await writeContractFunction('vaultManager')
        const usmContract = await readContractFunction('usm')
        let marketPrice = await usmContract.latestPrice()
        let tcr  = await contract.getTCR(marketPrice)
        tcr = ethers.utils.formatUnits(tcr) * 100
        if(Number(tcr) === Number(bigNumber)){
            return {tcr : null}
        }
        return {tcr}
    }catch(error){
        console.log("err-----------",error)
    }
}


const sortedVaultSize = async() =>{
    try{
        const contract = await readContractFunction('sortedVaults')
        const size = await contract.getSize()
        const vaultSize = size.toString()
        return {vaultSize}
    }catch(error){
        console.log(error)
    }
    
}

const changeMetaMaskNetwork = async() =>{
    try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x5' }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x5',
                  chainName: '...',
                  rpcUrls: ['https://...'] /* ... */,
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
}


export { 
    getMetaMask,
    getAccountDetails,
    getChainId,
    getNetworkName,
    readContractFunction,
    writeContractFunction,
    getContractAddress,
    getAddress,
    getEtherBalance,
    balanceOf,
    fetchAllInitialValues,
    fetchInitialAccVal,
    getTCR,
    marketPriceWei,
    marketPriceFn,
    sortedVaultSize,
    changeMetaMaskNetwork
}
