import { ethers } from 'ethers';
import swal from 'sweetalert';
import { createClient } from "urql";
import { api } from "../../common/constant";
import {writeContractFunction, marketPriceWei, getAddress, getMetaMask, readContractFunction} from '../../blockchain/commonFunction'

const _ = require('lodash')

const getDateTimeFromUnixTIme = (lastClaimed) =>{
    let lastCalim = new Date(lastClaimed * 1000)

    let minutes = lastCalim.getMinutes().toString().length == 1 ? '0'+lastCalim.getMinutes() : lastCalim.getMinutes(),
    hours = lastCalim.getHours().toString().length == 1 ? '0'+ lastCalim.getHours() : lastCalim.getHours(),
    ampm = lastCalim.getHours() >= 12 ? 'pm' : 'am',
    months = lastCalim.getMonth() + 1;
    return `${lastCalim.getDate()}/${months}/${lastCalim.getFullYear()}`
} 

const getStakedRewardVal = async() =>{
    try{
        const contract = await readContractFunction('stakingPool')
        const address = await getAddress()
        const totalStake = await contract.getTotalUSDAODeposits()
        const ethGain = await contract.getDepositorETHGain(address)
        const usdaoStaked = await contract.getCompoundedUSDAODeposit(address)
        const totalStakeVal = ethers.utils.formatEther(totalStake)
        const ethGainVal = ethers.utils.formatEther(ethGain)
        const usdaoStakedVal = ethers.utils.formatEther(usdaoStaked)
        return {totalStakeVal, ethGainVal, usdaoStakedVal}
    }catch (e){
        console.log(e)
    }
}


const stake = async(amount, setData, setDisable, refreshAllData) =>{
    let stake;
    try{
        const contract = await writeContractFunction('stakingPool')
        const amountWei = ethers.utils.parseEther(amount);
        stake = await contract.stakeUSDAO(amountWei) 
    }catch (e){
        setDisable({})
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
    }
    
    if(stake){
        const provider = await getMetaMask()
        provider.waitForTransaction(stake.hash)
            .then(async(res, err)=>{
                setDisable({})

                if(res.status){
                    setData({})
                    refreshAllData()
                    swal('Hurray!!! USDAO Staked Successfully')
                }else{
                    swal('Transaction Failed')
                }
            })
    }
} 

const onsubmitValidation = async(value, validateFields, setError) => {
	const err = {}
		Object.keys(validateFields).forEach((validate) => {
	    if (!_.isEmpty(value)) {
			if (validateFields[validate].required) {
				if (
					(typeof value[validate] !== 'boolean' &&
						(!value[validate] || value[validate] === '' || !value[validate].length) &&
						validateFields[validate].required)
				) {
					const { errorMessage } = validateFields[validate]
					err[validate] = errorMessage
					setError({ ...err })
					return err
				}
			}
			return err
        }else if(validateFields[validate].required){
            const { errorMessage } = validateFields[validate]
                    err[validate] = errorMessage
                    setError({ ...err })
        }
		})
	
	return err
}



const redeem = async(amount, setData, setDisable, refreshAllData) =>{
    let redeem;
    try{
        const contract = await writeContractFunction('stakingPool')
        const amountWei = ethers.utils.parseEther(amount);
        redeem = await contract.redeemUSDAO(amountWei) 
    }catch (e){
        setDisable({})
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
        if(String(e).includes('Cannot withdraw while there are vaults with ICR < MCR')){
            const el = document.createElement('div')
        el.innerHTML = "An operation that would result in Collateral ratio < 110% is not allowed. If your vault is showing Collateral ratio above 110, this could be because of redistributions (<a target='_blank' href='https://docs.usdao.io/usdao-v2/protocol/onvault-borrowing-and-staking-protocol/borrowing'>see docs</a> ). To view the true value please adjust the vault (do any transaction on vault page)"

        return swal({
          content: el,
        })  
        }
    }
    
    if(redeem){
        const provider = await getMetaMask()
        provider.waitForTransaction(redeem.hash)
            .then(async(res, err)=>{
                setDisable({})

                if(res.status){
                    setData({})
                    refreshAllData()
                    swal('Transaction Successfull')
                }else{
                    setDisable({})

                    swal('Transaction Failed')
                }
            })
    }
}


export const getAssetReward = async (account, setAssetRewardVal) => {
    const query = `
    {
      userRewards(where: {id: "${account}"}) {
        id
        assetRewarded
      }
    }
    `;
  const client = createClient({
    url: api,
  });
  await client.query(query).toPromise()
  .then((res)=>{
      let data = res.data && res.data.userRewards.length ? ethers.utils.formatEther(res.data.userRewards[0].assetRewarded ): null
      setAssetRewardVal(ethers.utils.formatEther(data))
    
  })
};


export {
    stake,
    onsubmitValidation,
    getStakedRewardVal,
    redeem,
    
}