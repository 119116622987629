import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import usdao from "../../../../assets/img/usdao.svg";
import {repayUsdao, collateralFn,  liquidationReserve, liquidationPriceFn, totalDeptFn} from '../helper';
import {truncateToDecimals} from '../../../common/utils';
import swal from 'sweetalert';


var cn = require('classnames');

const RepayUsdaoCal = ({repayUsdaoVal, calcualtion, vaultDetails}) =>{

  const totalDept = (parseFloat(vaultDetails.debt)) - parseFloat(repayUsdaoVal)

  return(
    <div className="deposite-info">
        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>Repay</p>
          <p>{truncateToDecimals(repayUsdaoVal, 6)} USDAO</p>
        </div>

        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>
            Liquidation reserve
            {["top"].map((placement) => (
              <OverlayTrigger
              key={placement}
              placement={placement}
              overlay={
                  <Tooltip id={`tooltip-${placement}`}>
                  200 USDAO reserve will be refunded/burnt once vault is closed the owner
                  </Tooltip>
              }
              >
              <button className="btn pt-0 pb-0 ps-2 pe-2">
                  <FontAwesomeIcon icon={faCircleInfo} />
              </button>
              </OverlayTrigger>
          ))}
          </p>

          <p>{liquidationReserve} USDAO</p>
        </div>

        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>
            Total debt
          </p>

          <p>{truncateToDecimals(totalDept)} USDAO</p>
        </div>
    </div>
  )
}


function RepayUsdao({refreshAllData, accountDetails, vaultDetails, setRatio}) {

  const [repayUsdaoVal, setRepayUsdaoVal] = useState()
  const [error, setError] = useState({})
  const [calcualtion,  setCalculation] = useState({})
  const [disable, setDisable] = useState(false)

  
  document.onkeydown = function(e) {
    if (e.defaultPrevented) {
      return; 
    }
    if (e.keyCode === 109 || e.keyCode === 189) {
      return false
    }
  }


  const calculateRatio = async(repayUsdaoVal) =>{
    let {coll, debt} = vaultDetails;
    debt = parseFloat(debt) - parseFloat(repayUsdaoVal || 0)
    if(debt <= 0){
      debt = 0
    }
    let dynamicRatio = await collateralFn(coll, debt.toString())
    setRatio(dynamicRatio)
  }

  useEffect(()=>{
    (async function(){
      setRatio(vaultDetails.ratio)
    })();
  },[])

  useEffect(()=>{
    if(repayUsdaoVal){
      (async function(){
          await calculateRatio(repayUsdaoVal)
          let liquidationPrice =  await liquidationPriceFn(repayUsdaoVal);
          let totalDept = await totalDeptFn(repayUsdaoVal)
          setCalculation({
            liquidationPrice : liquidationPrice,
            totalDept : totalDept
          })
        })();
    }
  },[repayUsdaoVal])

  const repayUsdaoChangeHandler = (e) =>{
    let {value} = e.target
    setRepayUsdaoVal(value)
  }

  const repayUsdaoOnSubmit = async() =>{
    setDisable(true)
    let {debt} = vaultDetails;
    const remianDebt = parseFloat(debt) - 700
    if(repayUsdaoVal > remianDebt){
      setDisable(false)
      return swal(`You can repay ${truncateToDecimals(remianDebt)} USDAO only.`)
    }
    await repayUsdao(repayUsdaoVal, refreshAllData, setDisable)
  }

  const maxHandler = () =>{
    let {debt} = vaultDetails;
    const maxUsdao = parseFloat(debt) - 700;
      setRepayUsdaoVal(maxUsdao.toString())
  }

  return (
    <>
      {/* ====================== Input ===================== */}
      <div className="tab-box-wrap">
        <div className="tab-box-info d-flex justify-content-between align-items-center">
          <p>Repay</p>
          <p>Wallet: {accountDetails && accountDetails.usmBalance ? truncateToDecimals(accountDetails.usmBalance, 6) : '0.00'}</p>
        </div>

        <div className="tab-box-values d-flex justify-content-between align-items-center">
          <input type="number" placeholder='Enter amount' id="number" onChange={repayUsdaoChangeHandler} value={repayUsdaoVal} min="0.01" step="0.01" />
          <div className="d-flex">
            <div className="value-status" onClick={maxHandler}>
              <p>Max</p>
            </div>
            <img src={usdao} alt="" />
          </div>
        </div>
      </div>
      {error.maxError && <div className="error">{error.maxError}</div>}
      {/* ====================== Input ===================== */}

      {/* ====================== Button ===================== */}
      <div className="d-flex justify-content-center mb-5">
        <a
          onClick={repayUsdaoOnSubmit}
          rel="noreferrer"
          className={cn("menu-link btn btn-gradient", (!parseFloat(repayUsdaoVal) || disable) && 'disabled')}
        >
          Confirm
        </a>
      </div>
      {repayUsdaoVal && <RepayUsdaoCal repayUsdaoVal={repayUsdaoVal} calcualtion={calcualtion} vaultDetails={vaultDetails}/>}


    </>
  );
}

export default RepayUsdao
