import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import bg from "../../../assets/banner/dashboard-bg.png";
import logo from "../../../assets/svg/logo.svg";
import usdao from "../../../assets/svg/usdao-logo.svg";
import { Link, NavLink } from "react-router-dom";
import { setAccountDetails } from "../../redux/action";
import { TailSpin } from "react-loader-spinner";
import profile from "../../../assets/img/profile.svg";
import dashboard from "../../../assets/icons/dashboard.svg";
import dashboardFill from "../../../assets/icons/dashboard-fill.svg";
import vault from "../../../assets/icons/vault.svg";
import vaultFill from "../../../assets/icons/vault-fill.svg";
import stake from "../../../assets/icons/stack.svg";
import stakeFill from "../../../assets/icons/stack-fill.svg";
import circle from "../../../assets/icons/circle.svg";
import circleFill from "../../../assets/icons/circle-fill.svg";
import Rewards from "../../../assets/icons/rewards.svg";
import docs from "../../../assets/icons/docs.svg";
import loader from "../../../assets/loader/loader.gif";
import metamask from "../../../assets/MetaMask_Fox.svg.png";
import docsFill from "../../../assets/icons/docs-fill.svg";
import usdaoLogo from "../../../assets/img/usdaoLogo.png";

import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "react-loading-dot";
import {
  getAccountDetails,
  fetchInitialAccVal,
  fetchAllInitialValues,
  getTCR,
  changeMetaMaskNetwork,
} from "../../blockchain/commonFunction";
import { truncateToDecimals } from "../utils";
import {
  faTwitter,
  faDiscord,
  faTelegram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./layout.scss";

const LoadingM = () => (
  <>
    <img src={logo} alt="" className="logo" />
    <div className="img-wrap">
      <img src={loader} alt="this slowpoke moves" width="150" />
    </div>

    {/* <h4>Loadingf</h4> */}
  </>
);

const NetworkType = () => (
  <>
    <img src={logo} alt="" className="logo" />
    <div className="img-wrap">
      <img src={loader} alt="this slowpoke moves" width="250" />
    </div>
    <div className="connect-rinkeby">
      Please connect to Goerli Account/Wallet.
    </div>
  </>
);

const MetaMaskLogOut = () => (
  <>
    <img src={logo} alt="" className="logo" />
    <div className="img-wrap">
      <img src={metamask} alt="this slowpoke moves" width="250" />
    </div>
    <div>
      Please log in to Metamask first. If not installed, please follow below
      link to install it.
      <br />
      <a target="_blank" href="https://metamask.io/download/">
        https://metamask.io/download
      </a>
    </div>
  </>
);

const Modal = ({ alertType, alertNetworkType, alertMetaMaskLogOut }) => {
  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      data-keyboard="false"
      data-backdrop="false"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="wrapper">
            {alertNetworkType ? (
              <NetworkType />
            ) : alertMetaMaskLogOut ? (
              <MetaMaskLogOut />
            ) : (
              alertType === "loading" && <LoadingM />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Layout = ({
  setToggleSidebar,
  alertNetworkType,
  toggleSidebar,
  alertType,
  setAlertType,
  alertMetaMaskLogOut,
  setAlertMetaMaskLogOut,
}) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  let { accountDetails = {}, transactionLoader = false } = selector;

  const [copyStatus, setCopyStatus] = useState(false);

  useEffect(() => {
    (async function () {
      await changeMetaMaskNetwork();
    })();
  }, []);

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", async (_) => {
      window.location.reload();
      await getAccountDetails(setAlertMetaMaskLogOut);
    });
    window.ethereum.on("chainChanged", (_) => window.location.reload());
  }

  function sidebar() {
    setToggleSidebar(!toggleSidebar);
  }

  const copyAddress = () => {
    if (!navigator.clipboard) {
      return;
    }
    setCopyStatus(true);
    navigator.clipboard.writeText(accountDetails.account);
  };
  useEffect(() => {
    let modal = document.querySelector(".modal");
    let transactionDot = document.querySelector(".transaction-load");

    if (transactionDot || modal) {
      let mainEl = document.querySelector(".dashboard-wrap");
      mainEl && mainEl.classList.add("pointer-event-none");
    } else {
      let mainEl = document.querySelector(".dashboard-wrap");
      mainEl && mainEl.classList.remove("pointer-event-none");
    }
  });

  return (
    <div>
      <div className="dashboard-bg">
        <img src={bg} alt="" />
      </div>

      <div
        className={
          toggleSidebar ? "dashboard-layout" : "dashboard-layout active"
        }
      >
        <div className="dashboard-header">
          <div className="header-leftside">
            <Link to="/dashboard">
              <img src={logo} alt="" className="logo" />
            </Link>

            <button className="sidebar-btn" onClick={sidebar}>
              <svg
                width="20"
                height="16"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.11479 8.97107C7.11479 9.39711 6.76941 9.74249 6.34336 9.74249H1.71479C1.28874 9.74249 0.943359 9.39711 0.943359 8.97107V8.97107C0.943359 8.54502 1.28874 8.19964 1.71479 8.19964H6.34336C6.76941 8.19964 7.11479 8.54502 7.11479 8.97107V8.97107ZM13.2862 5.11392C13.2862 5.53997 12.9408 5.88535 12.5148 5.88535H1.71479C1.28874 5.88535 0.943359 5.53997 0.943359 5.11392V5.11392C0.943359 4.68788 1.28874 4.34249 1.71479 4.34249H12.5148C12.9408 4.34249 13.2862 4.68788 13.2862 5.11392V5.11392ZM13.2862 1.25678C13.2862 1.68283 12.9408 2.02821 12.5148 2.02821H7.88622C7.46017 2.02821 7.11479 1.68283 7.11479 1.25678V1.25678C7.11479 0.830732 7.46017 0.485352 7.88622 0.485352H12.5148C12.9408 0.485352 13.2862 0.830732 13.2862 1.25678V1.25678Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>

          <div className="header-rightside">
            <div className="btn-wallet">
              {(accountDetails &&
                !!accountDetails.usmBalance &&
                truncateToDecimals(accountDetails.usmBalance)) ||
                "0.00"}{" "}
              USDAO
            </div>
            {/* <div className="btn-wallet">
              {(accountDetails &&
                !!accountDetails.assetBalance &&
                truncateToDecimals(accountDetails.assetBalance)) ||
                "0.00"}{" "}
              ASSET
            </div> */}

            <div className="currency-btn">
              {/* <div className="currency-wrap">
                <p className="eth">
                  {(accountDetails &&
                    accountDetails.etherBalance &&
                    truncateToDecimals(accountDetails.etherBalance)) ||
                    "0.00"}
                  ETH
                </p>
              </div> */}

              <div
                className="wallet-currency-wrap copy-address"
                onClick={copyAddress}
                onMouseMove={() => setCopyStatus(false)}
              >
                <p>
                  {accountDetails &&
                    accountDetails.account &&
                    accountDetails.account.substring(0, 4)}
                  ....
                  {accountDetails &&
                    accountDetails.account &&
                    accountDetails.account.substring(
                      accountDetails.account.length - 4
                    )}
                </p>

                <div className="profile-img">
                  <img src={profile} alt="" />
                </div>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>
                        {copyStatus ? "Copied" : "Copy"}
                      </Tooltip>
                    }
                  >
                    <button className="btn p-0 ms-2">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </button>
                  </OverlayTrigger>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-sidebar">
          <NavLink
            to="/dashboard"
            className="dashboard-nav "
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={dashboard} alt="" />
            </div>
            <div className="icon-fill">
              <img src={dashboardFill} alt="" />
            </div>
            Dashboard
          </NavLink>

          <NavLink
            to="/vault"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={vault} alt="" />
            </div>
            <div className="icon-fill">
              <img src={vaultFill} alt="" />
            </div>
            Vault
          </NavLink>

          <NavLink
            to="/stake"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={stake} alt="" />
            </div>
            <div className="icon-fill">
              <img src={stakeFill} alt="" />
            </div>
            Stake
          </NavLink>

          <NavLink
            to="/liquidation"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={circle} alt="" />
            </div>
            <div className="icon-fill">
              <img src={circleFill} alt="" />
            </div>
            Liquidation
          </NavLink>

          <NavLink
            to="/reward-history"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={Rewards} alt="" />
            </div>
            <div className="icon-fill">
              <img src={Rewards} alt="" />
            </div>
            Reward History
          </NavLink>

          <NavLink
            to="/vault-history"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={Rewards} alt="" />
            </div>
            <div className="icon-fill">
              <img src={Rewards} alt="" />
            </div>
            Vault History
          </NavLink>

          <a
            href="https://docs.usdao.io/usdao-v2/protocol/onvault-borrowing-and-staking-protocol"
            target="_blank"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={docs} alt="" />
            </div>
            <div className="icon-fill">
              <img src={docsFill} alt="" />
            </div>
            Docs
          </a>
          <a
            href="https://app.usdao.io/#/app"
            target="_blank"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={usdaoLogo} alt="" />
            </div>
            <div className="icon-fill">
              <img src={usdaoLogo} alt="usdao-logo" />
            </div>
            USDAO App
          </a>

          <div className="bottom-bar">
            <p>
              OnVault powered by{" "}
              <a href="https://usdao.io/" target="_blank">
                <img src={usdao} alt="" />
              </a>
            </p>
          </div>

          <div className="sidebar-social-wrap">
            <a
              href="https://twitter.com/usdao_io"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>

            <a
              href="https://discord.gg/JHbXb4kQwG"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </a>

            <a
              href="https://t.me/+cZgxay98TSo1ZmNh"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faTelegram} />
            </a>

            <a
              href="https://github.com/USDAO-Protocol"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
      </div>

      {(alertType || alertMetaMaskLogOut || alertNetworkType) && (
        <Modal
          alertType={alertType}
          alertMetaMaskLogOut={alertMetaMaskLogOut}
          alertNetworkType={alertNetworkType}
        />
      )}
      {transactionLoader && (
        <div className="transaction-load">
          <TailSpin color="#42FBFE" height={80} width={80} />
          {/* <Loading  size='2rem' background='linear-gradient(98.1deg, #42FBFE -74.25%, #226CD0 105.86%)'/> */}
        </div>
      )}
    </div>
  );
};

export default Layout;
