import { ethers, BigNumber } from 'ethers';
import swal from 'sweetalert';
import {writeContractFunction, marketPriceWei, getAddress, getMetaMask, readContractFunction} from '../../blockchain/commonFunction'

const liquidationReserve = 200;

const _maxFeePercentage = '500000000000000000';

const bigNumber = '115792089237316195423570985008687907853269984665640564039457.584007913129639935';


const collateralFn = async(eth, usdao) =>{
    try{
        const contract = await readContractFunction('hintHelper')
        const ethWei = ethers.utils.parseEther(eth);
        const usdaoWei = ethers.utils.parseEther(usdao);
        const marketPriceVal = await marketPriceWei();
        let ratio1 = await contract.computeCR(ethWei, usdaoWei, marketPriceVal) 
        const ratio = ethers.utils.formatEther(ratio1) 
        if(ratio === bigNumber){
            return null
        }
        return ratio * 100
    }catch(e){
        console.log(e)
    } 
}


const collateralFnForDeposite = async(eth, usdaoWei) =>{
    try{
        const contract = await readContractFunction('hintHelper')
        const ethWei = ethers.utils.parseEther(eth);
        const marketPriceVal = await marketPriceWei();
        let ratio1 = await contract.computeCR(ethWei, usdaoWei, marketPriceVal) 
        const ratio = ethers.utils.formatEther(ratio1) 
        if(ratio === bigNumber){
            return null
        }
        return ratio * 100
    }catch(e){
        console.log(e)
    } 
}


const liquidationPriceFn = (val) =>{
    val = parseFloat(val)
    let tenPercent = (0.1)* val,
    liquidationPrice = (val + tenPercent)/ val;
    return liquidationPrice || 0.00
}

const feeFn = (depositInputVal) =>{
    let {usdaoVal} = depositInputVal;
    let usadoWei = ethers.utils.parseEther(usdaoVal)
    return usadoWei.mul(BigNumber.from('5')).div(BigNumber.from('1000'))
}

const totalDeptFn = async(val) =>{
    let fee = (0.007) * parseFloat(val)
    return parseFloat(liquidationReserve) +  parseFloat(val) + parseFloat(fee)
}

const getCcr = async() =>{
    const contract = await readContractFunction('borrowerOperation')
    let ccr = await contract.CCR()
    ccr = ethers.utils.formatEther(ccr) * 100
    return ccr
}

const withdrawCollValidation = async(ethVal) =>{
    const contract = await readContractFunction('borrowerOperation')
    let enitreSystemColl  = await contract.getEntireSystemColl()
    let enitreSystemDebt = await contract.getEntireSystemDebt()
    
    let ethValWei =  ethers.utils.parseEther(ethVal);
    let collMinusEth = enitreSystemColl.sub(ethValWei)
    const collateral = await collateralFn(ethers.utils.formatEther(collMinusEth), ethers.utils.formatEther(enitreSystemDebt))
    const ccr = await getCcr()
    if(parseFloat(collateral) < parseFloat(ccr)){
        return true
    }
    return false
}

const borrowCollValidation = async(usdaoVal) =>{
    const contract = await readContractFunction('borrowerOperation')
    let enitreSystemColl  = await contract.getEntireSystemColl()
    let enitreSystemDebt = await contract.getEntireSystemDebt()
    
    let usdaoValWei =  ethers.utils.parseEther(usdaoVal);
    
    let multiplyusdao = parseFloat(usdaoVal) * 0.07;
    const multiplyusdaoWei = ethers.utils.parseEther(multiplyusdao.toString())
    let debtAddUsdao = enitreSystemDebt.add(usdaoValWei)
    const totalDebt = debtAddUsdao.add(multiplyusdaoWei)
    const collateral = await collateralFn(ethers.utils.formatEther(enitreSystemColl), ethers.utils.formatEther(totalDebt))
    const ccr = await getCcr()
    if(parseFloat(collateral) < parseFloat(ccr)){
        return true
    }
    return false
}

const checkRecovery = async() =>{
    const contract = await readContractFunction('vaultManager')
    const marketPriceVal = await marketPriceWei();
    const recoveryModeCheck = await contract.checkRecoveryMode(marketPriceVal)
    return {recoveryModeCheck}
}

const confirmDepositeFn = async(depositInputVal, refreshAllData, setDisableBtn, setDisable, currentEthBal) =>{
    let data;
    //check current ether balance
    let {etherVal, usdaoVal } = depositInputVal;

    if(currentEthBal<etherVal){
        setDisableBtn(false)
        setDisable(false)
        swal("You don't have sufficient ETH to proceed.");
        return
    }
    try{
        setDisableBtn(true)
        const etherWei = ethers.utils.parseEther(etherVal);
        const usdaoWei = ethers.utils.parseEther(usdaoVal);
        const contract = await writeContractFunction('borrowerOperation')
        const address = await getAddress()
            data = await contract.openVault(_maxFeePercentage, usdaoWei, address, address, {
                value : etherWei,
                gasLimit: '3000000' //need to change
            })
       
        
    }catch (e) {
        setDisableBtn(false)
        setDisable(false)
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
        if(String(e).includes('Operation must leave vault with ICR')){
            return swal('System is in Recover Mode. Vault cannot be opened below 125% collateral ratio')
        }
    }

    if(data){
        const provider = await getMetaMask()
        provider.waitForTransaction(data.hash)
            .then((res, err)=>{
                setDisable(false)
                if(res.status){
                    setDisableBtn(false)
                    swal('Hurray!!! Vault Successfully Created')
                    refreshAllData()
                }else{
                    setDisableBtn(false)
                    swal('Transaction Failed')
                }
            })
    }
    
}


const getVaultStatusFn = async() =>{
    const contract = await readContractFunction('vaultManager')
    const address = getAddress()
    let status = await contract.getVaultStatus(address)
    return Number(status) === 1 ? true : false 
}

const getVaultDebtColl = async() =>{
    const contract = await readContractFunction('vaultManager')
    const address = getAddress()
    let vault = await contract.Vaults(address) 
    let {coll, debt} = vault;
    coll = ethers.utils.formatEther(coll)
    debt = ethers.utils.formatEther(debt)
    return {coll, debt}
}

const addEth = async(ethVal, refreshAllData, setDisable) =>{
    let data;
    try{
        const contract = await writeContractFunction('borrowerOperation')
        const address = await getAddress()
        const etherWei = ethers.utils.parseEther(ethVal);
        data = await contract.addETH(address, address, {value : etherWei, gasLimit: '3000000'})
    }catch (e) {
        setDisable(false)
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
    }
    
    if(data){
        const provider = await getMetaMask()
        provider.waitForTransaction(data.hash)
            .then((res, err)=>{
                setDisable(false)
                if(res.status){
                    swal('Great!!! ETH Successfully Added')
                    refreshAllData()
                }else{
                    swal('Transaction Failed')
                }
            })
    }
}

const withdrawEth = async(ethVal, refreshAllData, setDisable) =>{
    let data;
    try{
        const contract = await writeContractFunction('borrowerOperation')
        const address = await getAddress()
        const etherWei = ethers.utils.parseEther(ethVal);
        data = await contract.withdrawETH( etherWei, address, address)
    }catch (e) {
        setDisable(false)
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
    }
    
    if(data){
        const provider = await getMetaMask()
        provider.waitForTransaction(data.hash)
            .then((res, err)=>{
                setDisable(false)
                if(res.status){
                    swal('Transaction Successfull')
                    refreshAllData()
                }else{
                    swal('Transaction Failed')
                }
            })
    }
}

const borrowUsdao = async(usdaoVal, refreshAllData, setDisable) =>{
    let data;
    try{
        const contract = await writeContractFunction('borrowerOperation')
        const address = await getAddress()
        const usdaoWei = ethers.utils.parseEther(usdaoVal);
        let _maxFeePercentage = ethers.utils.parseEther('0.5');
        data = await contract.withdrawUSDAO( _maxFeePercentage, usdaoWei, address, address)
    }catch (e) {
        setDisable(false)
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
    }
    
    if(data){
        const provider = await getMetaMask()
        provider.waitForTransaction(data.hash)
            .then((res, err)=>{
                setDisable(false)
                if(res.status){
                    swal('Transaction Successfull')
                    refreshAllData()
                }else{
                    swal('Transaction Failed')
                }
            })
    }
}

const repayUsdao = async(usdaoVal, refreshAllData, setDisable) =>{
    let data;
    try{
        const contract = await writeContractFunction('borrowerOperation')
        const address = await getAddress()
        const usdaoWei = ethers.utils.parseEther(usdaoVal);
        data = await contract.repayUSDAO( usdaoWei, address, address)
    }catch (e) {
        setDisable(false)
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
    }
    
    if(data){
        const provider = await getMetaMask()
        provider.waitForTransaction(data.hash)
            .then((res, err)=>{
                setDisable(false)
                if(res.status){
                    swal('Transaction Successfull')
                    refreshAllData()
                }else{
                    swal('Transaction Failed')
                }
            })
    }
}


const closeVaultFn = async(refreshAllData, setCloseButtonStatus) =>{
    let data;
    
        swal({
            text: "You are about to close your vault, please confirm.",
            buttons: true,
        })
        .then(async(willClose) => {
        if (willClose) {
            try{
            const contract = await writeContractFunction('borrowerOperation')
            data = await contract.closeVault()
            }catch (e) {
                setCloseButtonStatus(false);
                if (e.code === 4001) {
                    return swal('User denied transaction.')
                }
                if(String(e).includes('Only one vault in the system')){
                    return swal('Can not close last vault in the system.')
                }
            }
        } else {
            setCloseButtonStatus(false);
          }

          if(data){
            const provider = await getMetaMask()
            provider.waitForTransaction(data.hash)
                .then((res, err)=>{
                    setCloseButtonStatus(false)
                    if(res.status){
                        swal('Thanks For Trusting us.')
                        refreshAllData()
                    }else{
                        swal('Transaction Failed')
                    }
                })
        }
        });
}


const getMax = async(val) =>{
    try{
        const price = await marketPriceWei()
        const ethWei = ethers.utils.parseEther(val)
        const expectedICR = ethers.utils.parseEther('1.1')
        const priceDivICR = ethWei.mul(price).div(expectedICR)
        const subFrom = priceDivICR.sub(ethers.utils.parseEther('200'))
        const fee = subFrom.mul(BigNumber.from('7')).div(BigNumber.from('1000'))
        const final = subFrom.sub(fee)
        return ethers.utils.formatEther(final)
    }catch(e){
        console.log(e)
    } 
}


const getMaxBorrow = async(coll, debt, recoveryModeCheck) =>{
    try{
        const price = await marketPriceWei()
        const ethWei = ethers.utils.parseEther(coll)
        const debtWei = ethers.utils.parseEther(debt)
        const expectedICR = !!recoveryModeCheck ? ethers.utils.parseEther('1.25') : ethers.utils.parseEther('1.1')
        const priceDivICR = ethWei.mul(price).div(expectedICR)
        const subFrom = priceDivICR.sub(debtWei)
        const final = subFrom.mul(BigNumber.from('993')).div(BigNumber.from('1000'))
        const finalVal = ethers.utils.formatEther(final)
        return Math.sign(finalVal) === -1 ? '0' : finalVal
    }catch(e){
        console.log(e)
    } 
}


const getMaxWithdraw = async(coll, debt, recoveryModeCheck) =>{
    try{
        const price = await marketPriceWei()
        const ethWei = ethers.utils.parseEther(coll)
        const debtWei = ethers.utils.parseEther(debt)
        const expectedICR = !!recoveryModeCheck ? ethers.utils.parseEther('1.25') : ethers.utils.parseEther('1.1')
        const idealColl = debtWei.mul(expectedICR).div(price)
        if(idealColl.lt(ethWei)){
            let subVal = ethWei.sub(idealColl)
            return ethers.utils.formatEther(subVal)
        }else{
            return 0
        }
        
    }catch(e){
        console.log(e)
    } 
}

const sendColltoVaultFn = async() =>{
    let withdrawEthGain;
    try{
        const address = await getAddress()
        const contract = await writeContractFunction('stakingPool')
        withdrawEthGain = await contract.withdrawETHGainToVault(address, address) 
    }catch (e){
        if (e.code === 4001) {
            return swal('User denied transaction.')
           
            
        }
        if(String(e).includes('An operation that would result in ICR < MCR is not permitted')){
          const el = document.createElement('div')
          el.innerHTML = "An operation that would result in Collateral ratio<110% is not allowed. If your vault is showing Collateral ratio above 110, this could be because of redistributions (<a target='_blank' href='https://docs.usdao.io/usdao-v2/protocol/onvault-borrowing-and-staking-protocol/borrowing'>see docs</a> ). To view the true value please adjust the vault (do any transaction on vault page)"
  
          return swal({
            content: el,
          })
      }
    }
    
    if(withdrawEthGain){
        const provider = await getMetaMask()
        provider.waitForTransaction(withdrawEthGain.hash)
            .then(async(res, err)=>{
                if(res.status){
                    swal('Transaction Successfull')
                }else{
                    swal('Transaction Failed')
                }
            })
    }
  }

export {
    collateralFn,
    liquidationReserve,
    liquidationPriceFn,
    feeFn,
    totalDeptFn,
    confirmDepositeFn,
    getVaultStatusFn,
    getVaultDebtColl,
    addEth,
    withdrawEth,
    borrowUsdao,
    repayUsdao,
    closeVaultFn,
    withdrawCollValidation,
    borrowCollValidation,
    checkRecovery,
    getMax,
    collateralFnForDeposite,
    getMaxBorrow,
    getMaxWithdraw,
    sendColltoVaultFn
}