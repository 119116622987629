import React from 'react'

const Dashboard = React.lazy(() => import('../components/Dashboard/Dashboard'))
const Liquidation = React.lazy(() => import('../components/Liquidation/Liquidation'))
const Vault = React.lazy(() => import('../components/Vault/Vault'))
const Stake = React.lazy(() => import('../components/Stake/Stake'))
const RewardHistory = React.lazy(() => import('../components/RewardHistory/CompRewardHistory'))
const VaultHistory  = React.lazy(() => import('../components/VaultHistory/vaultHistory'))



const routes = [
  { path: '/dashboard', exact: true ,name: 'Dashboard', element: Dashboard },
  { path: '/liquidation', exact: true , name: 'Liquidation', element: Liquidation },
  { path: '/vault', exact: true, name: 'Vault', element: Vault },
  { path: '/stake', exact: true, name: 'Stake', element: Stake },
  { path: '/reward-history', exact: true,  name: 'Reward history', element:  RewardHistory},
  { path: '/vault-history', exact: true, name: 'Vault history', element: VaultHistory}
]

export default routes
