import { ethers } from 'ethers';
import swal from 'sweetalert';
import {createClient} from 'urql'
import { api } from "../../common/constant";
import {setTransactionLoader} from './../../redux/action';
import {writeContractFunction, marketPriceWei, getAddress, getMetaMask, readContractFunction} from '../../blockchain/commonFunction'


const liquidationData = async() =>{
    let arr = [], i = 0, next, vaults, data={}, icrRatio;
    let first;
    const sortedVaultContract = await readContractFunction('sortedVaults')
    const vaultManagerContract = await readContractFunction('vaultManager')
    first = await sortedVaultContract.getFirst();
    const marketPrice = await marketPriceWei()

    if(first !== '0x0000000000000000000000000000000000000000'){
        data['id'] = first
        arr.push(data)
        do{
        
            next = await sortedVaultContract.getNext(arr[i].id);
            data = {id : next}
            arr.push(data)
            vaults = await vaultManagerContract.Vaults(arr[i].id)
            icrRatio = await vaultManagerContract.getCurrentICR(arr[i].id, marketPrice)
            if(!!vaults.status){
                arr[i]['coll'] = vaults.coll
                arr[i]['debt'] = vaults.debt
                arr[i]['status'] = vaults.status
                arr[i]['icrRatio'] = ethers.utils.formatEther(icrRatio) * 100
            }
                i++;
           
        }
        while(!next.includes('0x0000000000000000000000000000000000000000'))
        arr.pop()
        arr.sort((a, b) => a.icrRatio - b.icrRatio)

        return arr
    } 
}


const getLiqData = async(setLiqData, setLoader) =>{
    const query = `
  {
    vaults(where: {status: OPEN}, orderBy: NICR, orderDirection: asc) {
      id
      debt
      coll
      NICR
      status
    }
  }`
  const client = createClient({
    url : api
  })
  await client.query(query).toPromise()
  .then((res)=>{
    setLoader && setLoader(false)
    res.data && setLiqData(res.data.vaults)
    return
  })
}


const liquidateFn = async(address, setDisableLiq, setLiquidationTableData, dispatch) =>{
    let data;
    try{
        const vaultManagerContract = await writeContractFunction('vaultManager')

        data = await vaultManagerContract.liquidate(address)
    }catch (e) {
        setDisableLiq(false)
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
        if(String(e).includes('Vault does not exist or is closed')){
            return swal('This vault is already liquidated by another address.')
        }
        if(String(e).includes('Cannot withdraw while there are vaults with ICR < ')){
            return swal('Under-Collateralized Vaults are not liquidated yet.')
        }
        if(String(e).includes('Only one vault in the system')){
            return swal('Can not liquidate when there is only one vault in the system')
        }
        if(String(e).includes('nothing to liquidate')){
            return swal('There are vaults with lower collateral ratio please liquidate them first')
        }
    }
    if(data){
        dispatch(setTransactionLoader(true))
        const provider = await getMetaMask()
        provider.waitForTransaction(data.hash)
            .then(async(res, err)=>{
                dispatch(setTransactionLoader(false))
                if(res.status){
                    setLiquidationTableData(await liquidationData())
                    setDisableLiq(false)
                    swal('Transaction Successfull')
                }else{
                    setDisableLiq(false)
                    swal('Transaction Failed')
                }
            })
    }
}

const liquidateAll = async(noOfVaults, setDisableLiq, setLiquidationTableData, dispatch) =>{
    let data;
    try{
        const vaultManagerContract = await writeContractFunction('vaultManager')

        data = await vaultManagerContract.liquidateVaults(noOfVaults)
    }catch (e) {
        setDisableLiq(false)
        if (e.code === 4001) {
            return swal('User denied transaction.')
        }
        if(String(e).includes('Only one vault in the system')){
            return swal('Can not liquidate when there is only one vault in the system')
        }
        if(String(e).includes('Vault does not exist or is closed')){
            return swal('This vault is already liquidated by another address.')
        }
    }
    if(data){
        dispatch(setTransactionLoader(true))
        const provider = await getMetaMask()
        provider.waitForTransaction(data.hash)
            .then(async(res, err)=>{
                dispatch(setTransactionLoader(true))
                if(res.status){
                    setLiquidationTableData(await liquidationData())
                    setDisableLiq(false)
                    swal('Transaction Successfull')
                }else{
                    setDisableLiq(false)
                    swal('Transaction Failed')
                }
            })
    }
}



export {
    liquidationData,
    liquidateFn,
    liquidateAll,
    getLiqData
}