export const contractDetails = {
    usm: {
		name: 'usm',
		tokenImage: 'https://onvault.usdao.io/usm.png',
		abi:  [
      {
        "inputs": [
          {
            "internalType": "contract Oracle",
            "name": "oracle_",
            "type": "address"
          },
          {
            "internalType": "address[]",
            "name": "addressesYouCantSendThisContractsTokensTo",
            "type": "address[]"
          },
          {
            "internalType": "address[]",
            "name": "contractsToAskToRejectSendsToThisContractsAddress",
            "type": "address[]"
          },
          {
            "internalType": "address",
            "name": "_timelockAddress",
            "type": "address"
          },
          {
            "internalType": "address payable",
            "name": "_foundationAddress",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "minWithdrawalSeconds",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_communityIssuance",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_ASSETSale",
            "type": "address"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "adjustment",
            "type": "uint256"
          }
        ],
        "name": "BidAskAdjustmentChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "enum USM.FeesTypes",
            "name": "feeType",
            "type": "uint8"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "newFeee",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "updateTime",
            "type": "uint256"
          }
        ],
        "name": "FeeChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "newFoundationAddress",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "updateTime",
            "type": "uint256"
          }
        ],
        "name": "FoundationAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "saleContractAddress",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "NewAssetLiquidityAdded",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "user",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "bool",
            "name": "newStatus",
            "type": "bool"
          }
        ],
        "name": "OptOutStatusChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "newOracleAddress",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "updateTime",
            "type": "uint256"
          }
        ],
        "name": "OracleContractsUpgraded",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "oraclePrice",
            "type": "uint256"
          }
        ],
        "name": "PriceChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "bool",
            "name": "underwater",
            "type": "bool"
          }
        ],
        "name": "UnderwaterStatusChanged",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "ASSETBurnFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "ASSETMintFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "BID_ASK_ADJUSTMENT_DECAY_PER_SECOND",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "BID_ASK_ADJUSTMENT_ZERO_OUT_PERIOD",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "DOMAIN_SEPARATOR",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MAX_DEBT_RATIO",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MINIMUM_DELAY",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MIN_FUM_BUY_PRICE_DECAY_PER_SECOND",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "PERMIT_TYPEHASH",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "PREFUND_END_TIMESTAMP",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAOBurnFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAODebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAOMintFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAOTransferFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "WAD",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "assetSaleContract",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "addAssetLiquidity",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "enum IUSM.Side",
            "name": "side",
            "type": "uint8"
          },
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "adjustment",
            "type": "uint256"
          }
        ],
        "name": "adjustedEthUsdPrice",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          }
        ],
        "name": "allowance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "wad",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "guy",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "storedTime",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "storedAdjustment",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "currentTime",
            "type": "uint256"
          }
        ],
        "name": "bidAskAdjustment",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "adjustment",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "bidAskAdjustment",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "adjustment",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address payable",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "usmToBurn",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "minEthOut",
            "type": "uint256"
          }
        ],
        "name": "burn",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "ethOut",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint8",
            "name": "_newburnFees",
            "type": "uint8"
          },
          {
            "internalType": "uint32",
            "name": "_base",
            "type": "uint32"
          }
        ],
        "name": "changeBurnFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_newFoundationAddress",
            "type": "address"
          }
        ],
        "name": "changeFoundationAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint8",
            "name": "_newmintFees",
            "type": "uint8"
          },
          {
            "internalType": "uint32",
            "name": "_base",
            "type": "uint32"
          }
        ],
        "name": "changeMintFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "timeSystemWentUnderwater",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "oracleEthUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustmentTimestamp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustment",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethPool",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "usmTotalSupply",
                "type": "uint256"
              }
            ],
            "internalType": "struct IUSM.LoadedState",
            "name": "ls",
            "type": "tuple"
          }
        ],
        "name": "checkForFreshOraclePrice",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "oraclePrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "adjustment",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "usmActualSupply",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ethPool_",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "oldTimeUnderwater",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "currentTime",
            "type": "uint256"
          }
        ],
        "name": "checkIfUnderwater",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "timeSystemWentUnderwater_",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "usmSupplyForFumBuys",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "debtRatio_",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ethInPool",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "usmSupply",
            "type": "uint256"
          }
        ],
        "name": "debtRatio",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "ratio",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "decimals",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_netDebtDecrease",
            "type": "uint256"
          }
        ],
        "name": "decreaseUSDAODebt",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ethInPool",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "usmSupply",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "roundUp",
            "type": "bool"
          }
        ],
        "name": "ethBuffer",
        "outputs": [
          {
            "internalType": "int256",
            "name": "buffer",
            "type": "int256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "timeSystemWentUnderwater",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "oracleEthUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustmentTimestamp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustment",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethPool",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "usmTotalSupply",
                "type": "uint256"
              }
            ],
            "internalType": "struct IUSM.LoadedState",
            "name": "ls",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "usmIn",
            "type": "uint256"
          }
        ],
        "name": "ethFromBurn",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "ethOut",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "adjGrowthFactor",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "ethPool",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "pool",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ethAmount",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "roundUp",
            "type": "bool"
          }
        ],
        "name": "ethToUsm",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "usmOut",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "foundationAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "foundationDefund",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "foundationFund",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "fum",
        "outputs": [
          {
            "internalType": "contract IFUM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "timeSystemWentUnderwater",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "oracleEthUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustmentTimestamp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustment",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethPool",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "usmTotalSupply",
                "type": "uint256"
              }
            ],
            "internalType": "struct IUSM.LoadedState",
            "name": "ls",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "fumSupply",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ethIn",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "debtRatio_",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "prefund",
            "type": "bool"
          }
        ],
        "name": "fumFromFund",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "fumOut",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "adjGrowthFactor",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "enum IUSM.Side",
            "name": "side",
            "type": "uint8"
          },
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "ethInPool",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "usmEffectiveSupply",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "fumSupply",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "prefund",
            "type": "bool"
          }
        ],
        "name": "fumPrice",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "fumTotalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "supply",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "ethAmount",
            "type": "uint256"
          }
        ],
        "name": "getAssetBurnFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenAmount",
            "type": "uint256"
          }
        ],
        "name": "getAssetMintFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenAmount",
            "type": "uint256"
          }
        ],
        "name": "getTransferFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "ethAmount",
            "type": "uint256"
          }
        ],
        "name": "getUSDAOBurnFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenAmount",
            "type": "uint256"
          }
        ],
        "name": "getUSDAOMintFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_netDebtIncrease",
            "type": "uint256"
          }
        ],
        "name": "increaseUSDAODebt",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "latestPrice",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "loadState",
        "outputs": [
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "timeSystemWentUnderwater",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "oracleEthUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustmentTimestamp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustment",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethPool",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "usmTotalSupply",
                "type": "uint256"
              }
            ],
            "internalType": "struct IUSM.LoadedState",
            "name": "ls",
            "type": "tuple"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "minUsmOut",
            "type": "uint256"
          }
        ],
        "name": "mint",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "usmOut",
            "type": "uint256"
          }
        ],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "nonces",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "onVaultBurn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "onVaultETHTransfer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "onVaultMint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "onVault_borrOperation",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "onVault_defaultPool",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "onVault_deployer",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "onVault_stabilityPool",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "onVault_vaultManager",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "optBackIn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "optOut",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "optedOut",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "oracle",
        "outputs": [
          {
            "internalType": "contract Oracle",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
          },
          {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
          },
          {
            "internalType": "bytes32",
            "name": "r",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
          }
        ],
        "name": "permit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_poolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_receiver",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "returnFromPool",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "revenueContract",
        "outputs": [
          {
            "internalType": "contract Revenue",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_sender",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_poolAddress",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "sendToPool",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_foundation",
            "type": "address"
          }
        ],
        "name": "setFoundation",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_onVault_borrOperation",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_onVault_stabilityPool",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_onVault_vaultManager",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_onVault_defaultPool",
            "type": "address"
          }
        ],
        "name": "setOnVaultAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "storedState",
        "outputs": [
          {
            "internalType": "uint32",
            "name": "timeSystemWentUnderwater",
            "type": "uint32"
          },
          {
            "internalType": "uint64",
            "name": "ethUsdPrice",
            "type": "uint64"
          },
          {
            "internalType": "uint64",
            "name": "oracleEthUsdPrice",
            "type": "uint64"
          },
          {
            "internalType": "uint32",
            "name": "bidAskAdjustmentTimestamp",
            "type": "uint32"
          },
          {
            "internalType": "uint64",
            "name": "bidAskAdjustment",
            "type": "uint64"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "timeSystemWentUnderwater",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "timestamp",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "timelockAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "dst",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "wad",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "src",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "dst",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "wad",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "contract Oracle",
            "name": "_newOracle",
            "type": "address"
          }
        ],
        "name": "upgradeOracle",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "timeSystemWentUnderwater",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "oracleEthUsdPrice",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustmentTimestamp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bidAskAdjustment",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "ethPool",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "usmTotalSupply",
                "type": "uint256"
              }
            ],
            "internalType": "struct IUSM.LoadedState",
            "name": "ls",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "ethIn",
            "type": "uint256"
          }
        ],
        "name": "usmFromMint",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "usmOut",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "adjShrinkFactor",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "enum IUSM.Side",
            "name": "side",
            "type": "uint8"
          },
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          }
        ],
        "name": "usmPrice",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "ethUsdPrice",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "usmAmount",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "roundUp",
            "type": "bool"
          }
        ],
        "name": "usmToEth",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "ethOut",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "version",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "stateMutability": "payable",
        "type": "receive"
      }
    ],
		address: {
			1: '0x03eb7Ce2907e202bB70BAE3D7B0C588573d3cECC',
			42: '0x6DEE30518efD2d2b32D8878c494309Ee9c0A4489',
			// 42: '0xCAC1daf45199454d982B818DcC8845B8a1AEE2D9',
			31337: '0x8Ad470569f02E93B7381d568615Dc3AB07fEB3Bd',
			1337: '0x8a3b2Bc31ac6B3835E698B3446e089FE86974FB0',
			4: '0x7A45D2E67A055447531793BF05C3CF84485ED650',
      5 : '0xbDe53c468F0E1230278455a77adefE1143c93778'
		}
    },
    fum: {
      name: 'fum',
      tokenImage: 'https://usdao.io/static/media/asset-token.de367b423f04d72447e01eb24c6e8b90.svg',
      abi:  [
        {
          "inputs": [
            {
              "internalType": "address[]",
              "name": "addressesYouCantSendThisContractsTokensTo",
              "type": "address[]"
            },
            {
              "internalType": "address[]",
              "name": "contractsToAskToRejectSendsToThisContractsAddress",
              "type": "address[]"
            },
            {
              "internalType": "address",
              "name": "_communityIssuance",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "_ASSETSale",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "_foundationAddress",
              "type": "address"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "user",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "bool",
              "name": "newStatus",
              "type": "bool"
            }
          ],
          "name": "OptOutStatusChanged",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "DOMAIN_SEPARATOR",
          "outputs": [
            {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "PERMIT_TYPEHASH",
          "outputs": [
            {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "allowance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "wad",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "guy",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "holder",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "burn",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "decimals",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "recipient",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "mint",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "nonces",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "optBackIn",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "optOut",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "optedOut",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            },
            {
              "internalType": "uint8",
              "name": "v",
              "type": "uint8"
            },
            {
              "internalType": "bytes32",
              "name": "r",
              "type": "bytes32"
            },
            {
              "internalType": "bytes32",
              "name": "s",
              "type": "bytes32"
            }
          ],
          "name": "permit",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "totalSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "dst",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "wad",
              "type": "uint256"
            }
          ],
          "name": "transfer",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "src",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "dst",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "wad",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "usm",
          "outputs": [
            {
              "internalType": "contract IUSM",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "version",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "stateMutability": "payable",
          "type": "receive"
        }
      ],
      address: {
        1: '0xf04a5D82ff8a801f7d45e9C14CDcf73defF1a394',
        42: '0x0cAe206C124CD82FC79F666402cB7dAe8030b0b6',
        // 42: '0x647Ef2F617dFFD1b1d007Ee4eFc6F62c188C1910',
        31337: '0x267cbD010d7E48421760dc8539898F8f1A866a50',
        1337: '0xbA4523C919633dE2A5590226e6b69dE1a48b2B2C',
        4: '0x139972feb95C15D4E6e0b26CF10Cf44f7a1684Ed',
        5 : '0xD541e9D78c8081e5514013Bd4615AEAF4ED71512'
      }
    },
    borrowerOperation: {
		name: 'borrowerOperation',
		abi:    [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_collSurplusPoolAddress",
            "type": "address"
          }
        ],
        "name": "CollSurplusPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_defaultPoolAddress",
            "type": "address"
          }
        ],
        "name": "DefaultPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_ETH",
            "type": "uint256"
          }
        ],
        "name": "ETHBalanceUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_gasPoolAddress",
            "type": "address"
          }
        ],
        "name": "GasPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_revenueContract",
            "type": "address"
          }
        ],
        "name": "RevenueContractAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_sortedVaultsAddress",
            "type": "address"
          }
        ],
        "name": "SortedVaultsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_stakingPoolAddress",
            "type": "address"
          }
        ],
        "name": "StakingPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_USDAOFee",
            "type": "uint256"
          }
        ],
        "name": "USDAOBorrowingFeePaid",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_USDAODebt",
            "type": "uint256"
          }
        ],
        "name": "USDAODebtUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_USMAddress",
            "type": "address"
          }
        ],
        "name": "USDAOTokenAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "arrayIndex",
            "type": "uint256"
          }
        ],
        "name": "VaultCreated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newVaultManagerAddress",
            "type": "address"
          }
        ],
        "name": "VaultManagerAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_debt",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_coll",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "stake",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "enum IBorrowerOperations.BorrowerOperation",
            "name": "operation",
            "type": "uint8"
          }
        ],
        "name": "VaultUpdated",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "BORROWING_FEE_FLOOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "CCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "DECIMAL_PRECISION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MIN_NET_DEBT",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "PERCENT_DIVISOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAO_GAS_COMPENSATION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "_100pct",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "addETH",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_maxFeePercentage",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_collWithdrawal",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_USDAOChange",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "_isDebtIncrease",
            "type": "bool"
          },
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "adjustVault",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "claimCollateral",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "closeVault",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "defaultPool",
        "outputs": [
          {
            "internalType": "contract IDefaultPool",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_debt",
            "type": "uint256"
          }
        ],
        "name": "getCompositeDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemColl",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemDebt",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "moveETHGainToVault",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_maxFeePercentage",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_USDAOAmount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "openVault",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_USDAOAmount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "repayUSDAO",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_defaultPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_stakingPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_gasPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_collSurplusPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_sortedVaultsAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_USMAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_revenueContract",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "sortedVaults",
        "outputs": [
          {
            "internalType": "contract ISortedVaults",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "stakingPoolAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "usm",
        "outputs": [
          {
            "internalType": "contract IUSM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "vaultManager",
        "outputs": [
          {
            "internalType": "contract IVaultManager",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_collWithdrawal",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "withdrawETH",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_maxFeePercentage",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_USDAOAmount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "withdrawUSDAO",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ],
		address: {
			4: '0xC05Ef68fd5158CDE6F1682c01C84782815226517',
      5 : '0x9a0Acec5D87E2e4B2a5F43c70b57A90eD0e15226'
		}
	},
    vaultManager: {
		name: 'vaultManager',
		abi:  [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_activePoolAddress",
            "type": "address"
          }
        ],
        "name": "ActivePoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_baseRate",
            "type": "uint256"
          }
        ],
        "name": "BaseRateUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newBorrowerOperationsAddress",
            "type": "address"
          }
        ],
        "name": "BorrowerOperationsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_collSurplusPoolAddress",
            "type": "address"
          }
        ],
        "name": "CollSurplusPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_defaultPoolAddress",
            "type": "address"
          }
        ],
        "name": "DefaultPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_gasPoolAddress",
            "type": "address"
          }
        ],
        "name": "GasPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_L_ETH",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_L_USDAODebt",
            "type": "uint256"
          }
        ],
        "name": "LTermsUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_lastFeeOpTime",
            "type": "uint256"
          }
        ],
        "name": "LastFeeOpTimeUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_liquidatedDebt",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_liquidatedColl",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_collGasCompensation",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_USDAOGasCompensation",
            "type": "uint256"
          }
        ],
        "name": "Liquidation",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_attemptedUSDAOAmount",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_actualUSDAOAmount",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_ETHSent",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_ETHFee",
            "type": "uint256"
          }
        ],
        "name": "Redemption",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_sortedVaultsAddress",
            "type": "address"
          }
        ],
        "name": "SortedVaultsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_stakingPoolAddress",
            "type": "address"
          }
        ],
        "name": "StakingPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_totalStakesSnapshot",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_totalCollateralSnapshot",
            "type": "uint256"
          }
        ],
        "name": "SystemSnapshotsUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newTotalStakes",
            "type": "uint256"
          }
        ],
        "name": "TotalStakesUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newUSDAOokenAddress",
            "type": "address"
          }
        ],
        "name": "USDAOTokenAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newIndex",
            "type": "uint256"
          }
        ],
        "name": "VaultIndexUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_debt",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_coll",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "enum IVaultManager.VaultManagerOperation",
            "name": "operation",
            "type": "uint8"
          }
        ],
        "name": "VaultLiquidated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_L_ETH",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_L_USDAODebt",
            "type": "uint256"
          }
        ],
        "name": "VaultSnapshotsUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_debt",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_coll",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "stake",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "enum IVaultManager.VaultManagerOperation",
            "name": "operation",
            "type": "uint8"
          }
        ],
        "name": "VaultUpdated",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "BETA",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "BOOTSTRAP_PERIOD",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "BORROWING_FEE_FLOOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "CCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "DECIMAL_PRECISION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "L_ETH",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "L_USDAODebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MAX_BORROWING_FEE",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MINUTE_DECAY_FACTOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "PERCENT_DIVISOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "REDEMPTION_FEE_FLOOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "SECONDS_IN_ONE_MINUTE",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAO_GAS_COMPENSATION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "VaultOwners",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "Vaults",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "debt",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "coll",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "stake",
            "type": "uint256"
          },
          {
            "internalType": "enum VaultManager.Status",
            "name": "status",
            "type": "uint8"
          },
          {
            "internalType": "uint128",
            "name": "arrayIndex",
            "type": "uint128"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "_100pct",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "addVaultOwnerToArray",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "index",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "applyPendingRewards",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "baseRate",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address[]",
            "name": "_vaultArray",
            "type": "address[]"
          }
        ],
        "name": "batchLiquidateVaults",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "borrowerOperationsAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_price",
            "type": "uint256"
          }
        ],
        "name": "checkRecoveryMode",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "closeVault",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "decayBaseRateFromBorrowing",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_collDecrease",
            "type": "uint256"
          }
        ],
        "name": "decreaseVaultColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_debtDecrease",
            "type": "uint256"
          }
        ],
        "name": "decreaseVaultDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "defaultPool",
        "outputs": [
          {
            "internalType": "contract IDefaultPool",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_USDAODebt",
            "type": "uint256"
          }
        ],
        "name": "getBorrowingFee",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_USDAODebt",
            "type": "uint256"
          }
        ],
        "name": "getBorrowingFeeWithDecay",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getBorrowingRate",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getBorrowingRateWithDecay",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_price",
            "type": "uint256"
          }
        ],
        "name": "getCurrentICR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getEntireDebtAndColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "debt",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "coll",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pendingUSDAODebtReward",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "pendingETHReward",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemColl",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemDebt",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getNominalICR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getPendingETHReward",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getPendingUSDAODebtReward",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_price",
            "type": "uint256"
          }
        ],
        "name": "getTCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getVaultColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getVaultDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_index",
            "type": "uint256"
          }
        ],
        "name": "getVaultFromVaultOwnersArray",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getVaultOwnersCount",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getVaultStake",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "getVaultStatus",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "hasPendingRewards",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_collIncrease",
            "type": "uint256"
          }
        ],
        "name": "increaseVaultColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_debtIncrease",
            "type": "uint256"
          }
        ],
        "name": "increaseVaultDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "lastETHError_Redistribution",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "lastFeeOperationTime",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "lastUSDAODebtError_Redistribution",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "liquidate",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_n",
            "type": "uint256"
          }
        ],
        "name": "liquidateVaults",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "removeStake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "rewardSnapshots",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "ETH",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "USDAODebt",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrowerOperationsAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_defaultPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_stakingPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_gasPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_collSurplusPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_usmAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_sortedVaultsAddress",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_num",
            "type": "uint256"
          }
        ],
        "name": "setVaultStatus",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "sortedVaults",
        "outputs": [
          {
            "internalType": "contract ISortedVaults",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "stakingPool",
        "outputs": [
          {
            "internalType": "contract IStakingPool",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalCollateralSnapshot",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalStakes",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalStakesSnapshot",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "updateStakeAndTotalStakes",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrower",
            "type": "address"
          }
        ],
        "name": "updateVaultRewardSnapshots",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "usm",
        "outputs": [
          {
            "internalType": "contract IUSM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
		address: {
			4: '0x57CeA51aC84A447Eef9A5429E9bF12E6F1578D2b',
      5 : '0x38a8C8EdF79423D92Fd071f3C4C809A4DC2e8022'
		}
	  },
    stakingPool: {
		name: 'stakingPool',
		abi: [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_account",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_feeAmount",
            "type": "uint256"
          }
        ],
        "name": "ASSETFeeCharged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newBorrowerOperationsAddress",
            "type": "address"
          }
        ],
        "name": "BorrowerOperationsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newCommunityIssuanceAddress",
            "type": "address"
          }
        ],
        "name": "CommunityIssuanceAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newDefaultPoolAddress",
            "type": "address"
          }
        ],
        "name": "DefaultPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_P",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_S",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_G",
            "type": "uint256"
          }
        ],
        "name": "DepositSnapshotUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_account",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "DistributeReward",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_ETH",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_USDAOLoss",
            "type": "uint256"
          }
        ],
        "name": "ETHGainWithdrawn",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint128",
            "name": "_currentEpoch",
            "type": "uint128"
          }
        ],
        "name": "EpochUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "EtherSent",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_frontEnd",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_kickbackRate",
            "type": "uint256"
          }
        ],
        "name": "FrontEndRegistered",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_frontEnd",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_P",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_G",
            "type": "uint256"
          }
        ],
        "name": "FrontEndSnapshotUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_frontEnd",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newFrontEndStake",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          }
        ],
        "name": "FrontEndStakeChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "_frontEnd",
            "type": "address"
          }
        ],
        "name": "FrontEndTagSet",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_G",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint128",
            "name": "_epoch",
            "type": "uint128"
          },
          {
            "indexed": false,
            "internalType": "uint128",
            "name": "_scale",
            "type": "uint128"
          }
        ],
        "name": "G_Updated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_P",
            "type": "uint256"
          }
        ],
        "name": "P_Updated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_S",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint128",
            "name": "_epoch",
            "type": "uint128"
          },
          {
            "indexed": false,
            "internalType": "uint128",
            "name": "_scale",
            "type": "uint128"
          }
        ],
        "name": "S_Updated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint128",
            "name": "_currentScale",
            "type": "uint128"
          }
        ],
        "name": "ScaleUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newSortedVaultsAddress",
            "type": "address"
          }
        ],
        "name": "SortedVaultsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newBalance",
            "type": "uint256"
          }
        ],
        "name": "StakingPoolETHBalanceUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newBalance",
            "type": "uint256"
          }
        ],
        "name": "StakingPoolUSDAOBalanceUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newDeposit",
            "type": "uint256"
          }
        ],
        "name": "UserDepositChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newVaultManagerAddress",
            "type": "address"
          }
        ],
        "name": "VaultManagerAddressChanged",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "BORROWING_FEE_FLOOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "CCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "DECIMAL_PRECISION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "P",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "PERCENT_DIVISOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "SCALE_FACTOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAO_GAS_COMPENSATION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "_100pct",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "borrowerOperations",
        "outputs": [
          {
            "internalType": "contract IBorrowerOperations",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "communityIssuance",
        "outputs": [
          {
            "internalType": "contract ICommunityIssuance",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "currentEpoch",
        "outputs": [
          {
            "internalType": "uint128",
            "name": "",
            "type": "uint128"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "currentScale",
        "outputs": [
          {
            "internalType": "uint128",
            "name": "",
            "type": "uint128"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "defaultPool",
        "outputs": [
          {
            "internalType": "contract IDefaultPool",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "depositSnapshots",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "S",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "P",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "G",
            "type": "uint256"
          },
          {
            "internalType": "uint128",
            "name": "scale",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "epoch",
            "type": "uint128"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "deposits",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint128",
            "name": "",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "",
            "type": "uint128"
          }
        ],
        "name": "epochToScaleToG",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint128",
            "name": "",
            "type": "uint128"
          },
          {
            "internalType": "uint128",
            "name": "",
            "type": "uint128"
          }
        ],
        "name": "epochToScaleToSum",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          }
        ],
        "name": "getCompoundedUSDAODeposit",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          }
        ],
        "name": "getDepositorASSETGain",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_depositor",
            "type": "address"
          }
        ],
        "name": "getDepositorETHGain",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getETH",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemColl",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemDebt",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getTotalUSDAODeposits",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "lastASSETError",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "lastETHError_Offset",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "lastUSDAOLossError_Offset",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_debtToOffset",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_collToAdd",
            "type": "uint256"
          }
        ],
        "name": "offset",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "redeemUSDAO",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "revenueContract",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrowerOperationsAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_defaultPoolAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_usdaoTokenAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_sortedVaultsAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_communityIssuanceAddress",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "sortedVaults",
        "outputs": [
          {
            "internalType": "contract ISortedVaults",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "stakeUSDAO",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "usm",
        "outputs": [
          {
            "internalType": "contract IUSM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "vaultManager",
        "outputs": [
          {
            "internalType": "contract IVaultManager",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_upperHint",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_lowerHint",
            "type": "address"
          }
        ],
        "name": "withdrawETHGainToVault",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "stateMutability": "payable",
        "type": "receive"
      }
    ],
		address: {
			4: '0x9a0acB710aC0aB1c44daac0001C75840eE3a9E44',
      5 : '0xE92979A042b99E7011CdCBd00D6ff0953eCa0Cf6'
		}
	  },
    hintHelper: {
		name: 'hintHelper',
		abi:  [
      {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_sortedVaultsAddress",
            "type": "address"
          }
        ],
        "name": "SortedVaultsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          }
        ],
        "name": "VaultManagerAddressChanged",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "BORROWING_FEE_FLOOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "CCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "DECIMAL_PRECISION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "PERCENT_DIVISOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "USDAO_GAS_COMPENSATION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "_100pct",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_coll",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_debt",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_price",
            "type": "uint256"
          }
        ],
        "name": "computeCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_coll",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_debt",
            "type": "uint256"
          }
        ],
        "name": "computeNominalCR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "pure",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "defaultPool",
        "outputs": [
          {
            "internalType": "contract IDefaultPool",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_CR",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_numTrials",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "_inputRandomSeed",
            "type": "uint256"
          }
        ],
        "name": "getApproxHint",
        "outputs": [
          {
            "internalType": "address",
            "name": "hintAddress",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "diff",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "latestRandomSeed",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemColl",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemColl",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEntireSystemDebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "entireSystemDebt",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_sortedVaultsAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "sortedVaults",
        "outputs": [
          {
            "internalType": "contract ISortedVaults",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "usm",
        "outputs": [
          {
            "internalType": "contract IUSM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "vaultManager",
        "outputs": [
          {
            "internalType": "contract IVaultManager",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
		address: {
			4: '0xd52C866863De41C9c9c50ab60B931Ea4a8fe12D5',
      5 : '0x8227D2331752055a87452D7d9AE486Aa9B8EfAb4'
		}
	  },
    collSurplus: {
		name: 'collSurplus',
		abi: [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newBorrowerOperationsAddress",
            "type": "address"
          }
        ],
        "name": "BorrowerOperationsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_account",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newBalance",
            "type": "uint256"
          }
        ],
        "name": "CollBalanceUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "EtherSent",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newUSMPoolAddress",
            "type": "address"
          }
        ],
        "name": "USMAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newVaultManagerAddress",
            "type": "address"
          }
        ],
        "name": "VaultManagerAddressChanged",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_account",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "accountSurplus",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "borrowerOperationsAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_account",
            "type": "address"
          }
        ],
        "name": "claimColl",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_account",
            "type": "address"
          }
        ],
        "name": "getCollateral",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getETH",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_borrowerOperationsAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_usdaoAddress",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "vaultManagerAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "stateMutability": "payable",
        "type": "receive"
      }
    ],
		address: {
			4: '0x8E7804b982a6Cf8Ed592C35A303066B2578AF9eD',
      5 : '0x2b65Bb8a8Daf0a300a84cAe95A7a9b670fBc2827'
		}
	  },
    activePool: {
		name: 'activePool',
		abi: [
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "_newActivePoolAddress",
                        "type": "address"
                    }
                ],
                "name": "ActivePoolAddressChanged",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "_ETH",
                        "type": "uint256"
                    }
                ],
                "name": "ActivePoolETHBalanceUpdated",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "_LUSDDebt",
                        "type": "uint256"
                    }
                ],
                "name": "ActivePoolLUSDDebtUpdated",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "_newBorrowerOperationsAddress",
                        "type": "address"
                    }
                ],
                "name": "BorrowerOperationsAddressChanged",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "_amount",
                        "type": "uint256"
                    }
                ],
                "name": "decreaseLUSDDebt",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "_newDefaultPoolAddress",
                        "type": "address"
                    }
                ],
                "name": "DefaultPoolAddressChanged",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "_newBalance",
                        "type": "uint256"
                    }
                ],
                "name": "ETHBalanceUpdated",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "_to",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "_amount",
                        "type": "uint256"
                    }
                ],
                "name": "EtherSent",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "_amount",
                        "type": "uint256"
                    }
                ],
                "name": "increaseLUSDDebt",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "_newBalance",
                        "type": "uint256"
                    }
                ],
                "name": "LUSDBalanceUpdated",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "previousOwner",
                        "type": "address"
                    },
                    {
                        "indexed": true,
                        "internalType": "address",
                        "name": "newOwner",
                        "type": "address"
                    }
                ],
                "name": "OwnershipTransferred",
                "type": "event"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "_account",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "_amount",
                        "type": "uint256"
                    }
                ],
                "name": "sendETH",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "_borrowerOperationsAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "_troveManagerAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "_stabilityPoolAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "_defaultPoolAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "_USMAddress",
                        "type": "address"
                    }
                ],
                "name": "setAddresses",
                "outputs": [],
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "_newStabilityPoolAddress",
                        "type": "address"
                    }
                ],
                "name": "StabilityPoolAddressChanged",
                "type": "event"
            },
            {
                "anonymous": false,
                "inputs": [
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "_newTroveManagerAddress",
                        "type": "address"
                    }
                ],
                "name": "TroveManagerAddressChanged",
                "type": "event"
            },
            {
                "stateMutability": "payable",
                "type": "receive"
            },
            {
                "inputs": [],
                "name": "borrowerOperationsAddress",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "defaultPoolAddress",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "getETH",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "getLUSDDebt",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "isOwner",
                "outputs": [
                    {
                        "internalType": "bool",
                        "name": "",
                        "type": "bool"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "NAME",
                "outputs": [
                    {
                        "internalType": "string",
                        "name": "",
                        "type": "string"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "owner",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "stabilityPoolAddress",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "troveManagerAddress",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "USM",
                "outputs": [
                    {
                        "internalType": "contract IUSM",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ],
		address: {
			4: '0x2b7b580064a2d5187141C6E0eA96Ec60D4cdCfB2',
      5 : ''
		}
	},
    defaultPool: {
		name: 'defaultPool',
		abi:  [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newDefaultPoolAddress",
            "type": "address"
          }
        ],
        "name": "DefaultPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_ETH",
            "type": "uint256"
          }
        ],
        "name": "DefaultPoolETHBalanceUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_USDAODebt",
            "type": "uint256"
          }
        ],
        "name": "DefaultPoolUSDAODebtUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newBalance",
            "type": "uint256"
          }
        ],
        "name": "ETHBalanceUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "EtherSent",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newStakingPoolAddress",
            "type": "address"
          }
        ],
        "name": "StakingPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_newBalance",
            "type": "uint256"
          }
        ],
        "name": "USDAOBalanceUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newActivePoolAddress",
            "type": "address"
          }
        ],
        "name": "USMPoolAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_newVaultManagerAddress",
            "type": "address"
          }
        ],
        "name": "VaultManagerAddressChanged",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "decreaseUSDAODebt",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getETH",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getUSDAODebt",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "increaseUSDAODebt",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "sendETHToUSMPool",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_usmAddress",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "usmAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "vaultManagerAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "stateMutability": "payable",
        "type": "receive"
      }
    ],
		address: {
			4: '0x0633cC1d65e13A244B968A7141E88Ff8BD1B4068',
      5 : '0x82367fb1919DFEB40Ce30ae546ca9a053E7c6b95'
		}
	},
    priceFeed: {
		name: 'priceFeed',
		abi:  [
            {
              "anonymous": false,
              "inputs": [
                {
                  "indexed": false,
                  "internalType": "uint256",
                  "name": "_lastGoodPrice",
                  "type": "uint256"
                }
              ],
              "name": "LastGoodPriceUpdated",
              "type": "event"
            },
            {
              "anonymous": false,
              "inputs": [
                {
                  "indexed": true,
                  "internalType": "address",
                  "name": "previousOwner",
                  "type": "address"
                },
                {
                  "indexed": true,
                  "internalType": "address",
                  "name": "newOwner",
                  "type": "address"
                }
              ],
              "name": "OwnershipTransferred",
              "type": "event"
            },
            {
              "anonymous": false,
              "inputs": [
                {
                  "indexed": false,
                  "internalType": "enum PriceFeed.Status",
                  "name": "newStatus",
                  "type": "uint8"
                }
              ],
              "name": "PriceFeedStatusChanged",
              "type": "event"
            },
            {
              "inputs": [],
              "name": "DECIMAL_PRECISION",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "ETHUSD_TELLOR_REQ_ID",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "MAX_PRICE_DEVIATION_FROM_PREVIOUS_ROUND",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "MAX_PRICE_DIFFERENCE_BETWEEN_ORACLES",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "NAME",
              "outputs": [
                {
                  "internalType": "string",
                  "name": "",
                  "type": "string"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "TARGET_DIGITS",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "TELLOR_DIGITS",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "TIMEOUT",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "fetchPrice",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "nonpayable",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "isOwner",
              "outputs": [
                {
                  "internalType": "bool",
                  "name": "",
                  "type": "bool"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "lastGoodPrice",
              "outputs": [
                {
                  "internalType": "uint256",
                  "name": "",
                  "type": "uint256"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "owner",
              "outputs": [
                {
                  "internalType": "address",
                  "name": "",
                  "type": "address"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "priceAggregator",
              "outputs": [
                {
                  "internalType": "contract AggregatorV3Interface",
                  "name": "",
                  "type": "address"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [
                {
                  "internalType": "address",
                  "name": "_priceAggregatorAddress",
                  "type": "address"
                },
                {
                  "internalType": "address",
                  "name": "_tellorCallerAddress",
                  "type": "address"
                }
              ],
              "name": "setAddresses",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            },
            {
              "inputs": [
                {
                  "internalType": "uint256",
                  "name": "_price",
                  "type": "uint256"
                }
              ],
              "name": "setPrice",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "status",
              "outputs": [
                {
                  "internalType": "enum PriceFeed.Status",
                  "name": "",
                  "type": "uint8"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            },
            {
              "inputs": [],
              "name": "tellorCaller",
              "outputs": [
                {
                  "internalType": "contract ITellorCaller",
                  "name": "",
                  "type": "address"
                }
              ],
              "stateMutability": "view",
              "type": "function"
            }
          ],
		address: {
			4: '0x93EA72bD87cd0371db49a55B6938562752A7c988',
      5 : ''
		}
	},
    sortedVaults : {
		name: 'sortedVaults',
		abi:   [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_borrowerOperationsAddress",
            "type": "address"
          }
        ],
        "name": "BorrowerOperationsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_id",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_NICR",
            "type": "uint256"
          }
        ],
        "name": "NodeAdded",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_id",
            "type": "address"
          }
        ],
        "name": "NodeRemoved",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_sortedDoublyLLAddress",
            "type": "address"
          }
        ],
        "name": "SortedVaultsAddressChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          }
        ],
        "name": "VaultManagerAddressChanged",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "borrowerOperationsAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_id",
            "type": "address"
          }
        ],
        "name": "contains",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "data",
        "outputs": [
          {
            "internalType": "address",
            "name": "head",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "tail",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "maxSize",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "size",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_NICR",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_prevId",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_nextId",
            "type": "address"
          }
        ],
        "name": "findInsertPosition",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getFirst",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getLast",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getMaxSize",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_id",
            "type": "address"
          }
        ],
        "name": "getNext",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_id",
            "type": "address"
          }
        ],
        "name": "getPrev",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getSize",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_id",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_NICR",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_prevId",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_nextId",
            "type": "address"
          }
        ],
        "name": "insert",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "isEmpty",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "isFull",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_id",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_newNICR",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_prevId",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_nextId",
            "type": "address"
          }
        ],
        "name": "reInsert",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_id",
            "type": "address"
          }
        ],
        "name": "remove",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_size",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_vaultManagerAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_borrowerOperationsAddress",
            "type": "address"
          }
        ],
        "name": "setParams",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_NICR",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_prevId",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_nextId",
            "type": "address"
          }
        ],
        "name": "validInsertPosition",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "vaultManager",
        "outputs": [
          {
            "internalType": "contract IVaultManager",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
		address: {
			4: '0x7E0b70E223C57427d841508Eb8066282451B2CD6',
      5 : '0xFE3fdf0f8c3AF5099c1F7b13e116f94b88c1e405'
		}
	},
    community: {
		name: 'community',
		abi: [
      {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_lqtyTokenAddress",
            "type": "address"
          }
        ],
        "name": "ASSETTokenAddressSet",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "_stakingPoolAddress",
            "type": "address"
          }
        ],
        "name": "StakingPoolAddressSet",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "_totalASSETIssued",
            "type": "uint256"
          }
        ],
        "name": "TotalASSETIssuedUpdated",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "ASSET",
        "outputs": [
          {
            "internalType": "contract IFUM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "ASSETSupplyCap",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "DECIMAL_PRECISION",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "ISSUANCE_FACTOR",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "NAME",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "SECONDS_IN_ONE_MINUTE",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "deploymentTime",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "issueASSET",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_account",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_ASSETamount",
            "type": "uint256"
          }
        ],
        "name": "sendASSET",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_assetTokenAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_stakingPoolAddress",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "stakingPoolAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalASSETIssued",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ],
		address: {
			4: '0xa9D913e2CA0807de3C0a7D8777Fd8a392ccDc7A9',
      5 : '0xea743875a3a7fB61A4F0D55e4317a8AcD017bd40'
		}
	},
  assetSale: {
		name: 'assetSale',
		abi:  [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "Received",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "EthPrice",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "WAD",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "foundationAddress",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "fum",
        "outputs": [
          {
            "internalType": "contract IFUM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getASSETBalance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "getEthBalance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "contract IUSM",
            "name": "_usm",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_foundationAddress",
            "type": "address"
          }
        ],
        "name": "setAddresses",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "transferAsset",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "usm",
        "outputs": [
          {
            "internalType": "contract IUSM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_to",
            "type": "address"
          }
        ],
        "name": "withdrawAnyETH",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "stateMutability": "payable",
        "type": "receive"
      }
    ],
		address: {
			4: '0xc995c1A40739D38A7C6CAF4Dc76094836277A860',
      5 : '0x0047F0320B82231cCb047aE87D745Ad8EdDc4097'
		}
	},

}