import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import usdao from "../../../../assets/img/usdao.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import {borrowUsdao, collateralFn, liquidationReserve, liquidationPriceFn, totalDeptFn, borrowCollValidation, getMaxBorrow} from '../helper';
import {truncateToDecimals} from '../../../common/utils';
import swal from 'sweetalert';

var cn = require('classnames');

const BorrowUsdaoInfo = ({borrowUsdaoVal, calcualtion, vaultDetails}) =>{

  const totalDept = parseFloat(borrowUsdaoVal) + (parseFloat(vaultDetails.debt)) + parseFloat(borrowUsdaoVal * 0.007)

  return(
    <div className="deposite-info">
        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>Borrow</p>

          <p>{truncateToDecimals(borrowUsdaoVal, 6)} USDAO</p>
        </div>

        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>
            Liquidation reserve
            {["top"].map((placement) => (
              <OverlayTrigger
              key={placement}
              placement={placement}
              overlay={
                  <Tooltip id={`tooltip-${placement}`}>
                  200 USDAO reserve will be refunded/burnt once vault is closed the owner
                  </Tooltip>
              }
              >
              <button className="btn pt-0 pb-0 ps-2 pe-2">
                  <FontAwesomeIcon icon={faCircleInfo} />
              </button>
              </OverlayTrigger>
          ))}
          </p>

          <p>{liquidationReserve} USDAO</p>
        </div>

        <div className="d-flex justify-content-between deposite-detail-wrap">
          <p>
            Total debt
          </p>

          <p>{truncateToDecimals(totalDept)} USDAO</p>
        </div>
    </div>
  )
}



function BorrowUsdao({refreshAllData, accountDetails, vaultDetails, setRatio, ratio}) {

  const [borrowUsdaoVal, setBorrowUsdaoVal] = useState()
  const [error, setError] = useState({})
  const [calcualtion,  setCalculation] = useState({})
  const [disableBtn,  setDisableBtn] = useState(false)
  const [disable, setDisable] = useState(false)

  document.onkeydown = function(e) {
    if (e.defaultPrevented) {
      return; 
    }
    if (e.keyCode === 109 || e.keyCode === 189) {
      return false
    }
  }
  const calculateRatio = async(borrowUsdaoVal) =>{
    let {coll, debt} = vaultDetails;
    debt = parseFloat(debt) + parseFloat(borrowUsdaoVal || 0) + parseFloat(borrowUsdaoVal || 0) * 0.007
    let dynamicRatio = await collateralFn(coll, debt.toString())
    setRatio(dynamicRatio)
  }

  useEffect(()=>{
    (async function(){
      setRatio(vaultDetails.ratio)
    })();
  },[])

  useEffect(()=>{
    if(borrowUsdaoVal){
      (async function(){
         await calculateRatio(borrowUsdaoVal)
          let liquidationPrice =  await liquidationPriceFn(borrowUsdaoVal);
          let totalDept = await totalDeptFn(borrowUsdaoVal)
          setCalculation({
            liquidationPrice : liquidationPrice,
            totalDept : totalDept
          })
          if(Object.keys(error).length){
            setDisableBtn(true)
          }
        })();
    }
  },[borrowUsdaoVal])

  const borrowUsdaoChangeHandler = async(e) =>{
    let {value} = e.target
    if(!value){
      setError({})
    }
    setBorrowUsdaoVal(value)
  }

  const borrowUsdaoOnSubmit = async() =>{
    setDisable(true)
    if(vaultDetails.recoveryModeCheck){
      setDisable(false)
      return swal('Borrow of additional USDAO is suspended as system has gone into Recover mode.')
    }
    if(await borrowCollValidation(borrowUsdaoVal)){
      setDisable(false)
      const el = document.createElement('div')
      el.innerHTML = "An operation that would result in Collateral ratio < 125% is not allowed. If your vault is showing Collateral ratio above 125, this could be because of redistributions (<a target='_blank' href='https://docs.usdao.io/usdao-v2/protocol/onvault-borrowing-and-staking-protocol/borrowing'>see docs</a> ). To view the true value please adjust the vault (do any transaction on vault page)"

      return swal({
        content: el,
      })
    }
    if(parseFloat(ratio) < 110){
      setDisable(false)
       const el = document.createElement('div')
        el.innerHTML = "An operation that would result in Collateral ratio < 110% is not allowed. If your vault is showing Collateral ratio above 110, this could be because of redistributions (<a target='_blank' href='https://docs.usdao.io/usdao-v2/protocol/onvault-borrowing-and-staking-protocol/borrowing'>see docs</a> ). To view the true value please adjust the vault (do any transaction on vault page)"

        return swal({
          content: el,
        })
    }
      await  borrowUsdao(borrowUsdaoVal, refreshAllData, setDisable)
  }

  const maxHandler = async() =>{
    let {coll, debt} = vaultDetails;
    const maxUsdao = await getMaxBorrow(coll, debt, accountDetails.recoveryCheck)
    let val = maxUsdao.split('.')
    setBorrowUsdaoVal(val[0].toString())
  }

  return (
    <>
      {/* ====================== Input ===================== */}
      <div className="tab-box-wrap">
        <div className="tab-box-info d-flex justify-content-between align-items-center">
          <p>Borrow</p>
          <p>Wallet: {accountDetails && accountDetails.usmBalance ? truncateToDecimals(accountDetails.usmBalance, 6) : '0.00'}</p>
        </div>

        <div className="tab-box-values d-flex justify-content-between align-items-center">
          <input type="number" placeholder='Enter amount' id="number" onChange={borrowUsdaoChangeHandler} value={borrowUsdaoVal} min="0.01" step="0.01"/>
          <div className="d-flex">
            <div className="value-status" onClick={maxHandler}>
              <p>Max</p>
            </div>
            <img src={usdao} alt="" />
          </div>
        </div>
      </div>
      {/* <p className='pb-3'>{vaultDetails.recoveryModeCheck ? '0' : '0.7'}% of fee will be applied to any additional borrow amount.</p> */}
      {/* {error.errorMsg && <div className="error">{error.errorMsg}</div>} */}
      {/* ====================== Input ===================== */}

      {/* ====================== Button ===================== */}
      <div className="d-flex justify-content-center mb-5">
        <a
          onClick={borrowUsdaoOnSubmit}
          rel="noreferrer"
          className={cn("menu-link btn btn-gradient", (disableBtn  || !parseFloat(borrowUsdaoVal) || disable) && 'disabled')}
        >
          Confirm
        </a>
      </div>
      {/* ====================== Button ===================== */}

      {/* ====================== Info ===================== */}
      {borrowUsdaoVal && <BorrowUsdaoInfo borrowUsdaoVal={borrowUsdaoVal} calcualtion={calcualtion} vaultDetails={vaultDetails}/>}
      {/* ====================== Info ===================== */}
    </>
  );
}

export default BorrowUsdao
