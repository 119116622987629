import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Content from "./content";
import Layout from "./Layout";

import {
  showClaimFn,
  assetCirculationFn,
} from "../../components/Dashboard/helper";
import {
  checkRecovery,
  getVaultDebtColl,
  getVaultStatusFn,
  collateralFn,
} from "../../components/Vault/helper";
import {
  getAssetReward,
  getStakedRewardVal,
} from "../../components/Stake/helper";
import {
  setDashboardDetails,
  setVaultDetails,
  setStakeDetails,
  setAccountDetails,
  setTransactionLoader,
} from "../../redux/action";
import {
  sortedVaultSize,
  fetchAllInitialValues,
  getTCR,
  fetchInitialAccVal,
  getAccountDetails,
} from "../../blockchain/commonFunction";

export const loadAllData = async (
  setAlertType,
  vaultDetails,
  dashboardDetails,
  stakeDetails,
  accountDetails,
  dispatch,
  setAlertMetaMaskLogOut
) => {
  let ratio;
  let details = await getAccountDetails(setAlertMetaMaskLogOut);

  const vaultSize = await sortedVaultSize();
  const stakeVal = await getStakedRewardVal();
  let totalSupplyVal = await fetchAllInitialValues();
  let assetCirculation = await assetCirculationFn();
  const vaultStatus = await getVaultStatusFn();
  const vault = await getVaultDebtColl();
  if (Number(vault.coll) && Number(vault.debt)) {
    ratio = await collateralFn(vault.coll, vault.debt);
  }

  let recoveryCheckVal = await checkRecovery();
  let tcr = await getTCR();
  let showClaimBtn = await showClaimFn();

  let initailVal = await fetchInitialAccVal();

  setInterval(async () => {
    const initailVal = await fetchInitialAccVal();
    let layoutData = Object.assign(initailVal, details);
    dispatch(setAccountDetails(layoutData));
  }, 3000);

  let dashboardData = Object.assign(
    assetCirculation,
    totalSupplyVal,
    { showClaimBtn },
    tcr
  );
  let vaultData = Object.assign(
    vaultSize,
    { vaultStatus },
    vault,
    recoveryCheckVal,
    { ratio }
  );
  let stakeData = Object.assign(stakeVal);
  let layoutData = Object.assign(initailVal, details);

  dispatch(setDashboardDetails(dashboardData));
  dispatch(setAccountDetails(layoutData));
  dispatch(setVaultDetails(vaultData));
  dispatch(setStakeDetails(stakeData));
  Promise.all([
    dashboardDetails,
    stakeDetails,
    vaultDetails,
    accountDetails,
  ]).then((val) => {
    if (val) {
      setAlertType("");
      dispatch(setTransactionLoader(false));
    }
  });
};

const LayoutWrapComp = () => {
  const [toggleSidebar, setToggleSidebar] = useState("false");
  const [alertType, setAlertType] = useState();
  const [alertMetaMaskLogOut, setAlertMetaMaskLogOut] = useState(false);
  const [alertNetworkType, setAlertNetworkType] = useState(false);

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  let {
    dashboardDetails = {},
    accountDetails = {},
    vaultDetails = {},
    stakeDetails = {},
  } = selector;

  useEffect(() => {
    (async function () {
      let details = await getAccountDetails(setAlertMetaMaskLogOut);
      if (Object.keys(details).length && details.networkName !== "goerli") {
        setAlertNetworkType(true);
      }
      setAlertType("loading");
      await loadAllData(
        setAlertType,
        vaultDetails,
        dashboardDetails,
        stakeDetails,
        accountDetails,
        dispatch,
        setAlertMetaMaskLogOut
      );
    })();
  }, []);

  return (
    <div className="dashboard-screen">
      <Layout
        setToggleSidebar={setToggleSidebar}
        alertNetworkType={alertNetworkType}
        setAlertMetaMaskLogOut={setAlertMetaMaskLogOut}
        alertMetaMaskLogOut={alertMetaMaskLogOut}
        toggleSidebar={toggleSidebar}
        alertType={alertType}
        setAlertType={setAlertType}
      />
      <div
        className={toggleSidebar ? "dashboard-wrap" : "dashboard-wrap active"}
      >
        <Content setAlertType={setAlertType} />
      </div>
    </div>
  );
};

export default LayoutWrapComp;
