import swal from "sweetalert";



export const truncateToDecimals = (num, dec = 2) => {
	const calcDec = Math.pow(10, dec)
	return String(Math.trunc(num * calcDec) / calcDec)
}

export const convertToInternationalCurrencySystem = (labelValue) => {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : truncateToDecimals(labelValue);

}


export const alertRecovery = (data) =>{
    if(data.recoveryModeCheck){
      swal('System has gone into recovery mode. Increase your collateral ration above 125% to prevent liquidation. Please also note, Withdrawal of ETH and Borrowing of USDAO has been suspended at the moment.')
    }
  }