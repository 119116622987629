export const setDashboardDetails = (payload) =>(
    {
        type : 'setDashboardDetails',
        payload
    }
)


export const setAccountDetails = (payload) =>(
    {
        type : 'setAccountDetails',
        payload
    }
)


export const setVaultDetails = (payload) =>(
    {
        type : 'setVaultDetails',
        payload
    }
)

export const setStakeDetails = (payload) =>(
    {
        type : 'setStakeDetails',
        payload
    }
)

export const setTransactionLoader = (payload) =>(
    {
        type : 'setTransactionLoader',
        payload
    }
)

