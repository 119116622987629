import React,{memo} from "react";
import { Col } from "react-bootstrap";
import GaugeChart from "react-gauge-chart/dist/GaugeChart";
import {truncateToDecimals} from '../../../common/utils'



const TCRCard = ({dashboardDetails={}}) =>{

  let ds = dashboardDetails.tcr ? truncateToDecimals(dashboardDetails.tcr) : 0
  
  if(ds < 100 ){
    ds = ds/100;
  }else if(ds < 1000 ){
    ds = ds/1000
  }else if(ds < 10000){
    ds = ds/10000
  }else if(ds < 100000){
    ds = ds/100000
  }

    return(
        <Col md={6}>
        <div className="dashboard-card">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="title-white">Total Collateral Ratio</h4>
          </div>
          <GaugeChart
            id="gauge-chart5"
            nrOfLevels={100}
            arcsLength={[
              2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35,
              2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35,
              2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35,
              2.35, 2.35, 2.35,
            ]}
            colors={["#42FBFE", "#226CD0"]}
            percent={ds}
            arcPadding={0.04}
            animate={false}
            needleColor={["#42FBFE"]}
            needleBaseColor={["#42FBFE"]}
            hideText={"true"}
          />

          <h4 className="title-white text-center mb-0">TCR { dashboardDetails && dashboardDetails.tcr ? truncateToDecimals(dashboardDetails.tcr) : '0.00'}%</h4>
        </div>
      </Col>
    )
}

export default memo(TCRCard)