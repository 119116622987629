import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import TotalSupply from "../../common/TotalSupply/totalSupply";
import { fetchInitialAccVal } from "../../blockchain/commonFunction";
import { getStakedRewardVal, getAssetReward } from "./helper";
import { setStakeDetails } from "../../redux/action";
import "./Stake.scss";
import { alertRecovery, truncateToDecimals } from "../../common/utils";
import StakeBox from "./StakeForm";
import { loadAllData } from "../../common/Layout/layoutWrapComp";
import { setTransactionLoader } from "./../../redux/action";

const Stake = ({ setAlertType }) => {
  const [assetRewardVal, setAssetRewardVal] = useState();

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);

  let {
    dashboardDetails = {},
    accountDetails = {},
    vaultDetails = {},
    stakeDetails = {},
  } = selector;

  const refreshAllData = async () => {
    dispatch(setTransactionLoader(true));

    loadAllData(
      setAlertType,
      vaultDetails,
      dashboardDetails,
      stakeDetails,
      accountDetails,
      dispatch
    );
  };

  useEffect(() => {
    (async function () {
      await alertRecovery(vaultDetails);

      if (accountDetails.account) {
        await getAssetReward(accountDetails.account, setAssetRewardVal);
      }
    })();
  }, [accountDetails]);

  return (
    <div className="d-flex flex-column align-items-center">
      <TotalSupply
        vaultDetails={vaultDetails}
        dashboardDetails={dashboardDetails}
        showClose={false}
      />
      <div className="vault-card">
        <Row className="gy-4">
          <Col md={12}>
            <StakeBox
              stakeDetails={stakeDetails}
              accountDetails={accountDetails}
              refreshAllData={refreshAllData}
            />
          </Col>

          <Col md={12}>
            <div className="dashboard-card">
              <div className="stack-details">
                <div className="deposite-info">
                  <div className="d-flex justify-content-between deposite-detail-wrap">
                    <p>
                      Total USDAO Staked in Protocol
                      {["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              Total amount of USDAO staked by the all the
                              depositors in the protocol
                            </Tooltip>
                          }
                        >
                          <button className="btn p-0 ms-2">
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </button>
                        </OverlayTrigger>
                      ))}
                    </p>

                    <p>
                      {stakeDetails.totalStakeVal
                        ? truncateToDecimals(stakeDetails.totalStakeVal)
                        : "00.00"}{" "}
                      <b>USDAO</b>
                    </p>
                  </div>

                  <div className="d-flex justify-content-between deposite-detail-wrap">
                    <p>
                      USDAO Staked
                      {["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              Amount of USDAO staked by the user individually in
                              the protocol
                            </Tooltip>
                          }
                        >
                          <button className="btn p-0 ms-2">
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </button>
                        </OverlayTrigger>
                      ))}
                    </p>

                    <p>
                      {parseFloat(stakeDetails.usdaoStakedVal)
                        ? truncateToDecimals(stakeDetails.usdaoStakedVal)
                        : "00.00"}{" "}
                      <b>USDAO</b>
                    </p>
                  </div>

                  {/* <div className="d-flex justify-content-between deposite-detail-wrap">
                        <p>Total ASSET Reward
                        {["top"].map((placement) => (
                            <OverlayTrigger
                              key={placement}
                              placement={placement}
                              overlay={
                                <Tooltip id={`tooltip-${placement}`}>
                                    Asset rewards are distributed according to the half life formula.
                                </Tooltip>
                              }
                            >
                              <button className="btn p-0 ms-2">
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </button>
                            </OverlayTrigger>
                          ))}
                        </p>
                        <p>{!!assetRewardVal ? truncateToDecimals(assetRewardVal) : "0.00"} <b>ASSET </b></p>
                      </div> */}
                  <div className="d-flex justify-content-between deposite-detail-wrap">
                    <p>
                      {" "}
                      ETH Gain
                      {["top"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Tooltip id={`tooltip-${placement}`}>
                              Every reward (either ETH gain or ASSET reward)
                              will be distributed when you'll try to
                              stake/redeem USDAO from the pool.
                            </Tooltip>
                          }
                        >
                          <button className="btn p-0 ms-2">
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </button>
                        </OverlayTrigger>
                      ))}
                    </p>

                    <p>
                      {parseFloat(stakeDetails.ethGainVal)
                        ? truncateToDecimals(stakeDetails.ethGainVal)
                        : "00.00"}{" "}
                      <b>ETH</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {/* <RewardHistory accountDetails ={accountDetails} /> */}
        </Row>
      </div>
    </div>
  );
};

export default Stake;
